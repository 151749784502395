import React from "react";
import { Panel, HStack, Icon, Text, Box } from "@pp/lib/ui/components";
import Icons from "@pp/lib/ui/app/Icons";

const Tooltip = ({ trigger, content, css = {}, onOpen = null }) => {
  const [open, setOpen] = React.useState(false);
  const [position, setPosition] = React.useState({ x: 20 });
  const ref = React.useRef();

  React.useEffect(() => {
    if (document?.body) {
      document.body.addEventListener("click", (event) => {
        setOpen(false);
      });
    }
  }, []);

  const toggle = (o) => {
    const pos = (ref.current as HTMLElement).getBoundingClientRect();
    var viewportWidth = window.innerWidth;
    if (pos.x > viewportWidth / 2) {
      setPosition({ x: -440 });
    } else {
      setPosition({ x: 20 });
    }

    setOpen(o);
    if (o && typeof onOpen === "function") {
      onOpen();
    }
  };

  return (
    <Box css={{ position: "relative", display: "inline-block", ...css }}>
      <span
        ref={ref}
        onClick={(event) => {
          event.stopPropagation();
          toggle(!open);
        }}
      >
        {trigger}
      </span>
      {open && (
        <Panel
          css={{
            position: "absolute",
            top: "0",
            left: `${position.x}px`,
            zIndex: "100",
          }}
        >
          <Panel.Content css={{ padding: "$4", width: "400px" }}>
            {content}
          </Panel.Content>
        </Panel>
      )}
    </Box>
  );
};

export default Tooltip;
