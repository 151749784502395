import React from "react";
import ReactDOM from "react-dom/client";
import { AppInitialiser } from "@pp/lib/ui/app";
import App from "./ui/app/App";

const main = async () => {
  const root = ReactDOM.createRoot(document.getElementById("main"));
  root.render(<AppInitialiser app={App} />);
};

main();
