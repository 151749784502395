import React from "react";
import { styled } from "@pp/lib/ui/theme";

const StyledDiv = styled("div", {
  display: "flex",
});

const Stack = React.forwardRef((props, ref) => {
  return (
    <StyledDiv ref={ref} css={props.css} {...props}>
      {props.children}
    </StyledDiv>
  );
});

const VStack = React.forwardRef((props, ref) => {
  const css = props.css || {};
  css.flexDirection = "column";
  return (
    <Stack ref={ref} css={css} {...props}>
      {props.children}
    </Stack>
  );
});

const HStack = React.forwardRef((props, ref) => {
  const css = props.css || {};
  css.flexDirection = "row";
  return (
    <Stack css={css} {...props}>
      {props.children}
    </Stack>
  );
});

export { Stack, HStack, VStack };
