import React from "react";
import { Box } from "../components";

const LogoText = ({ size, visible, css, colour = "#FFFFFF" }) => {
  const w = size;
  const h = size * 0.2;
  return (
    <Box
      css={{
        ...css,
        ml: "-50px",
        transition: `opacity 200ms ease-out`,
        opacity: visible ? 1 : 0,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={w}
        height={h}
        data-name="Layer 2"
        viewBox="0 0 158.56 41.01"
      >
        <g>
          <path
            fill={colour}
            d="m45.85,28.93c2.3,0,3.48-1.21,3.48-3.02,0-1.99-1.18-3.02-3.48-3.02h-3.4v6.04h3.4Zm-7.33-9.76h7.43c4.91,0,7.36,2.69,7.36,6.71s-3.18,6.77-7.36,6.77h-3.49v7.97h-3.94v-21.45Z"
          />
          <path
            fill={colour}
            d="m64.5,25.4l-.39,3.66c-.21-.03-.73-.09-1.3-.09-1.7,0-3.97.85-3.97,3.9v7.76h-3.73v-15.22h3.58l.15,1.81c.88-1.18,1.94-2.08,4.06-2.08.43,0,1.33.15,1.61.27"
          />
          <path
            fill={colour}
            d="m68.69,31.44h7.21c-.24-1.81-1.55-2.93-3.55-2.93-1.85,0-3.18,1.09-3.67,2.93m10.85,2.84h-10.91c.36,2.12,1.79,3.35,3.94,3.35,1.58,0,2.73-.63,3.58-2.08l2.85,1.66c-1.06,2.17-3.42,3.81-6.52,3.81-4.49,0-7.46-3.26-7.46-8.01s3.09-7.88,7.24-7.88,7.34,3.17,7.34,7.7c0,.45-.03.97-.06,1.45"
          />
          <path
            fill={colour}
            d="m80.51,37.93l2.58-2.23c.7,1.36,1.79,2.11,3.21,2.11,1.15,0,2-.39,2-1.33,0-.76-.3-1.15-2.94-1.93-2.79-.85-4.15-2.29-4.15-4.8,0-2.75,2.09-4.65,5.09-4.65,2.12,0,3.82.94,4.7,2.81l-2.61,1.84c-.48-1.06-1.15-1.45-2.12-1.45-.88,0-1.61.45-1.61,1.27s.61,1.21,3,1.93c2.73.82,4.09,2.23,4.09,4.86s-2.3,4.65-5.55,4.65c-2.61,0-4.61-1.09-5.7-3.08"
          />
          <path
            fill={colour}
            d="m92.97,33.07c0-5.01,3.39-7.94,7.64-7.94s5.79,2.36,6.67,4.53l-3.3,1.6c-.48-1.15-1.3-2.57-3.36-2.57-2.18,0-3.85,1.42-3.85,4.38s1.67,4.41,3.85,4.41,3.03-1.39,3.46-2.6l3.42,1.42c-.88,2.48-2.91,4.71-6.88,4.71-4.24,0-7.64-2.99-7.64-7.94"
          />
          <path
            fill={colour}
            d="m109.26,25.4h3.73v15.22h-3.73v-15.22Zm-.55-4.29c0-1.36,1.06-2.39,2.42-2.39s2.39,1.03,2.39,2.39-1.06,2.42-2.39,2.42-2.42-1.03-2.42-2.42"
          />
          <path
            fill={colour}
            d="m118.85,31.44h7.21c-.24-1.81-1.55-2.93-3.55-2.93-1.85,0-3.18,1.09-3.67,2.93m10.85,2.84h-10.91c.36,2.12,1.79,3.35,3.94,3.35,1.58,0,2.73-.63,3.58-2.08l2.85,1.66c-1.06,2.17-3.42,3.81-6.52,3.81-4.49,0-7.46-3.26-7.46-8.01s3.09-7.88,7.24-7.88,7.34,3.17,7.34,7.7c0,.45-.03.97-.06,1.45"
          />
          <path
            fill={colour}
            d="m145.81,32.07v8.55h-3.73v-8.22c0-2.27-1.03-3.72-3.09-3.72-1.94,0-3.21,1.15-3.21,3.9v8.04h-3.73v-15.22h3.58l.15,1.36c.88-.97,2.21-1.63,4.06-1.63,3.12,0,5.97,1.87,5.97,6.95"
          />
          <path
            fill={colour}
            d="m158.56,39.8c-1.18.88-2.27,1.21-3.79,1.21-3.27,0-5.27-1.72-5.27-5.35v-6.71h-2.27v-3.56h2.27v-3.59l3.73-.36v3.96h4.12v3.56h-4.12v6.34c0,1.63.64,2.36,2.09,2.36.73,0,1.55-.36,1.97-.76l1.27,2.9Z"
          />
        </g>
      </svg>
    </Box>
  );
};

export default LogoText;
