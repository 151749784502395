import React, { useState } from "react";

import {
  Panel,
  VStack,
  HStack,
  Text,
  Select,
  Icon,
  Checkbox,
  HRule,
  Button,
  CollapsibleSection,
} from "@pp/lib/ui/components";
import Icons from "@pp/lib/ui/app/Icons";

const CommentsFilter = ({
  touchpoints,
  overall,
  /*experienceThemes,*/ onChange,
}) => {
  return (
    <Select
      css={{ zIndex: 100 }}
      trigger={() => {
        return <SelectTrigger />;
      }}
      content={(onClose) => {
        return (
          <Filter
            touchpoints={touchpoints}
            overall={overall}
            // experienceThemes={experienceThemes}
            onApply={() => {
              onClose();
            }}
            onChange={onChange}
          />
        );
      }}
    />
  );
};

const SelectTrigger = ({}) => {
  return (
    <HStack
      css={{
        cursor: "pointer",
        color: "$ppForest",
        padding: "$1",
        width: "120px",
        height: "42px",
        boxSizing: "border-box",
        gap: "$2",
        borderBottom: "2px solid $ppForest",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Icon
        icon={Icons.Slider}
        css={{ fontSize: "20px", display: "inline-block", mx: "$1" }}
      />
      <Text css={{ color: "$ppForest" }}>Filter</Text>
      <Icon
        icon={Icons.ChevronDown}
        css={{
          fontSize: "30px",
          display: "inline-block",
        }}
      />
    </HStack>
  );
};

const Filter = ({
  touchpoints,
  overall,
  /*experienceThemes,*/ onApply,
  onChange,
}) => {
  const [touchpointISChecked, setTouchpointISChecked] = useState(true);
  const [overallISChecked, setOverallISChecked] = useState(overall);
  // const [threeWordsISChecked, setThreeWordsISChecked] = useState(true);

  const [localTouchpoints, setLocalTouchpoints] = useState(touchpoints);
  // const [localExperienceThemes, setLocalExperienceThemes] =
  //   useState(experienceThemes);

  React.useEffect(() => {
    handleChange();
  }, [
    localTouchpoints,
    // localExperienceThemes,
    touchpointISChecked,
    overallISChecked,
    // threeWordsISChecked,
  ]);

  const handleChange = () => {
    onChange({
      overall: overallISChecked,
      // threeWords: threeWordsISChecked,
      includeTouchpoints: touchpointISChecked,
      touchpoints: localTouchpoints.filter((tp) => tp.checked),
      // experienceThemes: localExperienceThemes.filter((et) => et.checked),
    });
  };

  const handleClearFilters = () => {
    setTouchpointISChecked(true);
    setOverallISChecked(true);
    // setThreeWordsISChecked(true);
    handleAllChange(localTouchpoints, true, setLocalTouchpoints);
    // handleAllChange(localExperienceThemes, true, setLocalExperienceThemes);
  };

  const handleAllChange = (arr, checked, setMethod) => {
    const newArray = arr.map((v) => {
      return {
        ...v,
        checked,
      };
    });
    setMethod(newArray);
  };

  const handleAllTouchpoints = (checked: boolean) => {
    handleAllChange(localTouchpoints, checked, setLocalTouchpoints);
  };

  // const handleAllExperienceThemes = (checked: boolean) => {
  //   handleAllChange(localExperienceThemes, checked, setLocalExperienceThemes);
  // };

  const handleCheckboxSetChange = (value, checked, arr, setMethod) => {
    let newArr = arr.map((v) => {
      if (v.value === value) {
        return { ...v, checked };
      }
      return v;
    });
    setMethod(newArr);
  };

  const handleTouchpointChange = (value, checked) => {
    handleCheckboxSetChange(
      value,
      checked,
      localTouchpoints,
      setLocalTouchpoints
    );
  };

  // const handleExperienceThemeChange = (value, checked) => {
  //   handleCheckboxSetChange(
  //     value,
  //     checked,
  //     localExperienceThemes,
  //     setLocalExperienceThemes
  //   );
  // };

  return (
    <Panel
      css={{
        position: "absolute",
        top: "42px",
        right: "-120px",
        width: "400px",
        p: "$4",
        zIndex: 100,
      }}
    >
      <VStack css={{ gap: "$3" }}>
        <Text.H2>Filter comments</Text.H2>
        <VStack css={{ gap: "$3" }}>
          <Checkbox
            label="Touchpoint improvement suggestion"
            checked={touchpointISChecked}
            onCheckedChange={(checked) => {
              setTouchpointISChecked(checked);
            }}
          />

          <CollapsibleSection
            open={touchpointISChecked}
            css={{ marginLeft: "$5" }}
          >
            <CheckboxSection
              title="Touchpoints"
              options={localTouchpoints}
              handleAllChange={handleAllTouchpoints}
              handleOneChange={handleTouchpointChange}
            />
            {/* <CheckboxSection
              title="Experience Themes"
              options={localExperienceThemes}
              handleAllChange={handleAllExperienceThemes}
              handleOneChange={handleExperienceThemeChange}
            /> */}
          </CollapsibleSection>
        </VStack>
        <Checkbox
          label="Overall improvement suggestion"
          checked={overallISChecked}
          onCheckedChange={(checked) => {
            setOverallISChecked(checked);
          }}
        />
        {/* <Checkbox
          label="What are 3 words to describe your experience?"
          checked={threeWordsISChecked}
          onCheckedChange={(checked) => {
            setThreeWordsISChecked(checked);
          }}
        /> */}
        {/* <HRule /> */}
        <HStack css={{ justifyContent: "end", gap: "$2" }}>
          <Button css={{ width: "120px" }} onClick={handleClearFilters}>
            Clear Filters
          </Button>
          <Button.Primary
            css={{ width: "120px" }}
            onClick={() => {
              onApply();
            }}
          >
            Done
          </Button.Primary>
        </HStack>
      </VStack>
    </Panel>
  );
};

const CheckboxSection = ({
  title,
  options,
  handleAllChange,
  handleOneChange,
}) => {
  return (
    <VStack css={{ borderBottom: "1px solid $grey2" }}>
      <HStack
        css={{
          justifyContent: "space-between",
          borderBottom: "1px solid $grey2",
          py: "$2",
          my: "$1",
        }}
      >
        <Text.H4>{title}</Text.H4>
        <HStack css={{ gap: "$2" }}>
          <Button.Link
            onClick={() => {
              handleAllChange(true);
            }}
          >
            Select All
          </Button.Link>
          |
          <Button.Link
            onClick={() => {
              handleAllChange(false);
            }}
          >
            Deselect All
          </Button.Link>
        </HStack>
      </HStack>
      <CheckboxSet
        options={options}
        onChange={(value, checked) => {
          handleOneChange(value, checked);
        }}
        css={{ maxHeight: "250px", marginLeft: "$3" }}
      />
    </VStack>
  );
};

const CheckboxSet = ({ options, onChange, css = {} }) => {
  return (
    <VStack css={{ gap: "$2", overflowY: "auto", ...css }}>
      {options.map((option) => {
        return (
          <React.Fragment key={option.value}>
            <Checkbox
              label={option.label}
              checked={option.checked}
              onCheckedChange={(checked) => {
                onChange(option.value, checked);
              }}
            />
          </React.Fragment>
        );
      })}
    </VStack>
  );
};

export default CommentsFilter;
