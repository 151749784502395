import { colours } from "@pp/lib/ui/theme";
import React from "react";

const labelCols = [
  colours.greys.grey0,
  colours.greys.grey0,
  colours.ppForest,
  colours.ppForest,
  colours.greys.grey0,
];

export const StackedBar = ({ data }) => {
  const total = Object.values(data).reduce(
    (sum: number, val: number) => sum + val,
    0
  ) as number;

  return (
    <div
      style={{
        display: "flex",
        maxWidth: "550px",
        height: "30px",
        borderRadius: "5px",
        overflow: "hidden",
        border: "1px solid #ddd",
        marginLeft: "4px",
      }}
    >
      {Object.entries(data).map(([key, value]: [string, number], index) => {
        const widthPercentage = (value / total) * 100;
        return (
          <div
            key={key}
            style={{
              width: `${widthPercentage}%`,
              backgroundColor: colours.agreeColours[key],
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: labelCols[index],
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {`${Math.round(widthPercentage)}%`}
          </div>
        );
      })}
    </div>
  );
};

export const ProgressBar = ({ percentage, style = {} }) => (
  <div style={{ display: "flex", alignItems: "baseline", gap: "4px" }}>
    <div
      style={{
        width: "50%",
        height: "10px",
        backgroundColor: "#e0e0e0",
        borderRadius: "5px",
        display: "flex",
        ...style,
      }}
    >
      <div
        style={{
          height: "10px",
          width: `${percentage * 100}%`,
          backgroundColor: "#0d3b66",
          borderRadius: "5px",
        }}
      />
    </div>
    <span>{Math.round(percentage * 100)}%</span>
  </div>
);
