import React from "react";
import { styled } from "@pp/lib/ui/theme";

import { HStack, Icon } from "./";
import Icons from "../app/Icons";

const cellStyle = {
  padding: "$3",
  textAlign: "left",
  maxWidth: "400px",
  borderRight: "1px solid $minContrast",
  "&:last-child": {
    borderRight: "none",
  },
};

const TableElement = styled("table", {
  borderCollapse: "separate",
  fontSize: "$2",
  width: "100%",
});
const Head = styled("thead");
const Body = styled("tbody");
const Row = styled("tr");
const HeadCell = styled("th", {
  ...cellStyle,
  borderBottom: "1px solid $ppForest",
  padding: "$2",
  position: "sticky",
  top: "0px",
  backgroundColor: "$background",
  color: "$ppForest",
  fontWeight: "normal",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "$grey0",
  }
});
const Cell = styled("td", {
  ...cellStyle,
  borderBottom: "1px solid $minContrast",
});

const HeadRow = ({ trCSS = {}, theadCSS = {}, children }) => {
  return (
    <Head css={theadCSS}>
      <Row css={trCSS}>{children}</Row>
    </Head>
  );
};

const SimpleTable = ({
  headers,
  items,
  itemRenderer,
  itemKey,
  onRowClick = () => {},
  headerStyles = {},
  defaultSort = 0,
  defaultAscending = true,
  ...rest
}) => {
  const [sortIndex, setSortIndex] = React.useState(defaultSort);
  const [ascending, setAscending] = React.useState(defaultAscending);

  const sortMethod = headers[sortIndex].sortMethod;
  const sorted = sortMethod
    ? items.toSorted((a, b) => {
        return sortMethod(a, b, ascending);
      })
    : items;

  return (
    <TableElement {...rest}>
      <Head css={{ ...headerStyles }}>
        <Row>
          {headers.map((header, index) => {
            const css = {
              fontWeight: index === sortIndex ? "bold" : "normal",
            };
            return (
              <HeadCell
                key={index}
                css={css}
                onClick={() => {
                  if (index === sortIndex) {
                    setAscending(!ascending);
                  } else {
                    setAscending(true);
                    setSortIndex(index);
                  }
                }}
              >
                <HStack css={{ justifyContent: "space-between" }}>
                  {header.label}
                  {index === sortIndex && (
                    <Icon
                      css={{ fontSize: "20px", ml: "$3" }}
                      icon={ascending ? Icons.ChevronUp : Icons.ChevronDown}
                    />
                  )}
                </HStack>
              </HeadCell>
            );
          })}
        </Row>
      </Head>
      <Body>
        {sorted.map((item, index) => {
          const cellContent = itemRenderer(item, index);
          const ind = itemKey ? itemKey(item) : index;
          return (
            <Row
              key={ind}
              onClick={(event) => {
                if (event.defaultPrevented) {
                  return;
                }
                onRowClick();
              }}
            >
              {cellContent.map((content, index) => {
                return <Cell key={index}>{content}</Cell>;
              })}
            </Row>
          );
        })}
      </Body>
    </TableElement>
  );
};

const Table = {
  Table: TableElement,
  HeadRow,
  Head,
  Body,
  Row,
  HeadCell,
  Cell,
  Simple: SimpleTable,
};

export default Table;
