const NPS_PLUS_TREND = `NPS+ is the main indicator of sentiment which is the sum of five different measures. It is a summation of how your users feel about your place, how loyal they are to it, how it compares to other places, if they advocate for it, how much it exceeded their expectations and how satisfied they were with their visit. 

User information helps to build individual scores for each of the subfactors. These subfactors then contribute to overall the NPS+ score. NPS+ scores range from -100 to +100.

The NPS+ score provides a snapshot of place performance and is easily compared to other benchmarks that use a basic NPS score, creating a point of comparison. Subfactor scores help shed light on more detailed aspects of overall performance and illuminate potential areas for an improved sentiment.`;

const COLLECTION_STATISTICS = `Collection Statistics show the number of responses received for a particular period that contribute to the scores shown on this dashboard. 

Response numbers are displayed as Monthly (the selected period), as well as the daily average and a trend of responses over past months. ‘Total Responses’ shows the total number of responses received in the specific months displayed on the trend bar chart.

It is important to track your Response Numbers, as more responses equates to more accurate data. Generally, the higher the response numbers, the more reliable the data.`;

const TOUCHPOINTS_OVERVIEW = `Touchpoints are specific factors or areas within the venue that are experienced by customers during a typical visit. Some examples include Parking, Seating Areas or Restrooms. Each Touchpoint is given a score (out of 100) to represent its overall performance, which is displayed for the reported month and previous months in the adjacent line chart.

Users provide feedback on the Touchpoint only if they have experienced it, rating the Touchpoint’s performance and how satisfied they were with their interaction. The collective user experience generates the Touchpoint Score, ranging from 0 to 100.

Understanding the performance of specific Touchpoints within the place helps to identify those parts of your place that are successful and those that require development, painting a more detailed and accurate picture of the overall performance.`;

const EXPERIENCE_THEMES = `Experience Themes are scores relating to specific aspects experienced on-site across multiple touchpoints. It collects information from across a range of sources to help establish how safe they feel, how clean they think the place is, the level of service they received, how well it is designed for their needs, if everything worked well for them. 

User data is quantified into a score for each experience theme, ranging from 0 to 100.

Experience Theme scores are a way of capturing data about aspects which cover multiple Touchpoints, e.g., safety or cleanliness. These scores are useful in understanding how your place is performing in different overarching aspects across the entire on-site experience. `;

const RECENT_SUGGESTIONS = `These are verbatim comments received from the user, relating to their thoughts on how to enhance the experience of your place.

While aggregate data has many benefits, it’s also valuable to understand the voice of the user and their raw feelings (comments) about place. As these comments are expressed in the user’s own words, they can provide a richer level of detail about how to improve the on-site experience. `;

const TOUCHPOINT_SCORE = `Touchpoints are specific factors or areas within the venue that are experienced by customers during a typical visit. Some examples include Parking, Seating Areas or Restrooms. Each Touchpoint is given a score to represent its overall performance, which is displayed for the reported month, previous month and against the industry benchmark. These are shown in separate lines on the circumference of the chart. This chart also includes the percentage change from the previous month as indicated by the insert and an up or down arrow accordingly.

Users provide feedback on the Touchpoint only when they have experienced it, indicating how the Touchpoint performed and how satisfied they were with their interaction. The collective user experience generates the Touchpoint Score, ranging from 0 to 100.

Understanding the performance of specific Touchpoints within the place helps to identify which parts of your place are successful and which require development, painting a more detailed and accurate picture of overall place performance.`;

const TOUCHPOINT_TREND = `Touchpoints are specific factors or areas within the venue that are experienced by customers during a typical visit. Each Touchpoint is given a score to represent its overall performance, which is displayed in a line chart for the reported month and previous months, showing its change over time. The shading used on the chart shows the Industry Benchmark displayed as ranges for the top, middle and bottom performers, and where your place sits in relation to it.

Users provide feedback on the Touchpoint only when they have experienced it, indicating how the Touchpoint performed and how satisfied they were with their interaction. The collective user experience generates the Touchpoint Score, ranging from 0 to 100.

Understanding the performance of specific touchpoints within your place helps to identify which parts of your place are successful and which require development, and how these factors have changed over time.`;

const STATEMENT_SCORE = `Users provide information on specific aspects of the Touchpoints by answering questions related to their experience.

Users indicate how strongly they agree or disagree with an aspect about a particular Touchpoint. The collective user experience generates the score, presented as a percentage of the total possible.

Understanding the performance of specific touchpoints within your place helps to identify those parts of your place are that successful and those that require development, painting a more detailed and accurate picture of overall place performance.`;

const TOUCHPOINT_DETAIL_BREAKDOWN = `This chart shows the percentage of responses provided against the Touchpoint Statements, when users answer questions related to their experience.

Users indicate how strongly they agree or disagree with a statement about a particular Touchpoint aspect, demonstrating how successful the Touchpoint was and how satisfied they were with their interaction. The chart shows how many strongly agree, strongly disagree and all options within this range.

Understanding the performance of specific Touchpoints within the place helps to identify those parts of your place that are successful and those that require development, painting a more detailed and accurate picture of overall place performance.`;

const TOUCHPOINT_SUGGESTIONS = `This section provides the most popular topics discussed by users regarding the Touchpoint, supplemented by recommendations for how to address user issues and concerns.

The comment themes are grouped by topic, as indicated on the left. The percentage refers to how many of the comments mention each topic. The AI-generated suggestions (right) summarise the comments in the first sentence, then provide suggestions which would improve, mitigate or alleviate the challenges users expressed with the Touchpoint.

This module helps to identify the most important (and most discussed) topics from your users from a large amount of qualitative data.`;

const TOUCHPOINT_COMMENTS = `These are verbatim comments received from the user, relating to their thoughts on how to enhance the experience of the Touchpoint.

While aggregate data has many benefits, it’s also valuable to understand the voice of the user and their raw feelings (comments). This can often offer the ‘why’ to various scores on place performance.`;

const COMMENTS = `This page shows all qualitative, verbatim comments received from users throughout the data collection. Filters can be used to show specific comments, either by date, by survey question or by origin.

While aggregate data has many benefits, it’s also valuable to understand the voice of the user and their raw feelings (comments). This can often offer the ‘why’ to various scores on place performance.`;

export default {
  NPS_PLUS_TREND,
  COLLECTION_STATISTICS,
  TOUCHPOINTS_OVERVIEW,
  EXPERIENCE_THEMES,
  RECENT_SUGGESTIONS,
  TOUCHPOINT_SCORE,
  TOUCHPOINT_TREND,
  STATEMENT_SCORE,
  TOUCHPOINT_DETAIL_BREAKDOWN,
  TOUCHPOINT_SUGGESTIONS,
  TOUCHPOINT_COMMENTS,
  COMMENTS,
};
