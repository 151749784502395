import React from "react";
import { Link as RRLink } from "react-router-dom";
import { colours } from "@pp/lib/ui/theme";

const Link: React.FC = ({ to, children = null, css = {}, onClick = null }) => {
  return (
    <RRLink
      to={to}
      style={{
        color: colours.ppForest,
        textDecoration: "underline",
        ...css,
      }}
      onClick={onClick}
    >
      {children}
    </RRLink>
  );
};

export default Link;
