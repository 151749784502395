import React from "react";
import { Panel, HStack, Icon, Text, Box, Tooltip } from "@pp/lib/ui/components";
import Icons from "@pp/lib/ui/app/Icons";
import Analytics from "@pp/utils/analytics";

const WidgetPanel = (props) => {
  return (
    <Panel css={{ height: "100%", ...(props.css ?? {}) }}>
      <Panel.Header>
        <HStack>
          {props.title}
          {props.tooltip && (
            <PanelTooltip
              text={props.tooltip}
              analyticEvent={props.tooltipAnalyticEvent}
            />
          )}
          <Actions actions={props.actions} />
        </HStack>
      </Panel.Header>
      <Panel.Content
        css={{
          display: "flex",
          height: "calc(100% - $panelHeaderHeight)",
          width: "100%",
          justifyContent: "center",
          boxSizing: "border-box",
        }}
      >
        {props.children}
      </Panel.Content>
    </Panel>
  );
};

const Actions = ({ actions }) => {
  if (actions) {
    return <>{actions}</>;
  }
  return null;
};

const PanelTooltip = ({ text, analyticEvent }) => {
  const trackTooltip = Analytics.useTrackTooltip();
  return (
    <Tooltip
      trigger={
        <Text.Small css={{ ml: "$1", color: "$grey1", cursor: "pointer" }}>
          <Icon icon={Icons.Question} />
        </Text.Small>
      }
      content={
        <Text.Small css={{ color: "$neutral8" }}>
          <Text.Paragraphs text={text} />
        </Text.Small>
      }
      onOpen={() => {
        if (analyticEvent !== undefined) {
          trackTooltip(analyticEvent);
        }
      }}
    />
  );
};

export default WidgetPanel;
