const globalStyles = {
  "*": {
    margin: 0,
    padding: 0,
    fontFamily: "Prescient, OpenSans, -apple-system, system-ui, sans-serif;",
    boxModel: "border-box",
  },
  a: {
    textDecoration: "none",
    color: "$text",
    "&:hover": {
      textDecoration: "underline",
    },
  },

  html: {
    backgroundColor: "$background",
    color: "$text",
  },
};

export default globalStyles;
