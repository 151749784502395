import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Loader,
  VStack,
  HStack,
  Text,
  Panel,
  Table,
  Container,
  Input,
  Icon,
  NoData,
  Tooltip,
} from "@pp/lib/ui/components";
import Icons from "@pp/lib/ui/app/Icons";
import CommentsFilter from "./CommentsFilter";
import CommentsDateOption from "./CommentsDateOption";
import { processTouchpoints } from "../../utils/data";
import api from "@pp/lib/net/api-service";
import {
  sortByField,
  getDefaultTimeRange,
  formatDate,
  trimChars,
  yearMonthDay,
} from "@pp/lib/utils";
import Analytics from "@pp/utils/analytics";

import {
  AccountContext,
  VenueContext,
  BenchmarkContext,
} from "@pp/ui/app/GlobalContexts";
import Tooltips from "@pp/ui/widgets/shared/tooltips";

const MIN_SEARCH_LENGTH = 3;
const HEADER_HEIGHT = 80;

const commentsDefaultTimeRange = () => {
  const now = new Date();
  const from = new Date(now.getFullYear(), now.getMonth() - 3, 1);
  const to = new Date(now.getFullYear(), now.getMonth(), 0);
  return { from, to };
};

const Comments = () => {
  Analytics.useScreenView("comments");

  const { account } = React.useContext(AccountContext) as any;
  const { venue } = React.useContext(VenueContext) as any;
  const [touchpoints, setTouchpoints] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [filter, setFilter] = useState(null);
  const [dateRange, setDateRange] = useState(commentsDefaultTimeRange());
  const trackSearch = Analytics.useTrackEvent("search_comments");
  const trackFilter = Analytics.useTrackEvent("filter_comments");
  const trackDateChange = Analytics.useTrackEvent("date_change_comments");

  const touchpointsAPICall = async () => {
    const defaultRange = getDefaultTimeRange();
    const result = await api.touchpoints({
      accountId: account.shortId,
      venueIds: [venue.id],
      ...defaultRange,
    });
    const tps = processTouchpoints(result, null, defaultRange, null);
    setTouchpoints(tps);
  };

  useEffect(() => {
    setTouchpoints(null);
    touchpointsAPICall();
  }, [venue]);

  return (
    <Loader
      css={{ padding: "30px", boxSizing: "border-box" }}
      data={touchpoints}
      content={() => {
        return (
          <VStack css={{ padding: "$4", gap: "$4" }}>
            <CommentsHeader
              touchpoints={touchpoints}
              dateRange={dateRange}
              onSearchChange={(searchVal) => {
                setSearchTerm(searchVal);
                if (searchVal && searchVal.length >= MIN_SEARCH_LENGTH) {
                  trackSearch({ term: searchVal });
                }
              }}
              onFilterChange={(f) => {
                setFilter(f);
                trackFilter({ filter: f });
              }}
              onDateChange={(range) => {
                setDateRange(range);
                console.log(range);
                trackDateChange({
                  from: yearMonthDay(range.from),
                  to: yearMonthDay(range.to),
                });
              }}
            />
            <Panel
              css={{
                position: "absolute",
                width: "calc(100% - $4 * 2)",
                top: `${HEADER_HEIGHT}px`,
                height: `calc(100% - ${HEADER_HEIGHT}px - $4)`,
                paddingTop: "0",
              }}
            >
              <CommentsResults
                searchTerm={searchTerm}
                filter={filter}
                dateRange={dateRange}
              />
            </Panel>
          </VStack>
        );
      }}
    />
  );
};

const CommentsHeader = ({
  touchpoints,
  dateRange,
  onSearchChange,
  onFilterChange,
  onDateChange,
}: {
  touchpoints: any;
  dateRange: { from: Date; to: Date };
  onSearchChange: Function;
  onFilterChange: Function;
  onDateChange: Function;
}) => {
  const [searchParams] = useSearchParams();
  const prefilteredTouchpoint = searchParams.get("touchpoint");
  const trackTooltip = Analytics.useTrackTooltip();

  // let experienceThemes = [
  //   { id: 1, label: "Navigation" },
  //   { id: 2, label: "Cleanliness" },
  //   { id: 3, label: "Safety" },
  //   { id: 4, label: "Experience" },
  //   { id: 5, label: "Design" },
  // ];

  touchpoints = touchpoints.map((tp) => {
    return {
      label: tp.label,
      checked:
        prefilteredTouchpoint === tp.id || prefilteredTouchpoint === null,
      value: tp.id,
    };
  });

  const overall = prefilteredTouchpoint === null;

  // experienceThemes = experienceThemes.map((et) => {
  //   return {
  //     ...et,
  //     value: et.id,
  //     checked: true,
  //   };
  // });

  return (
    <HStack
      css={{
        justifyContent: "space-between",
      }}
    >
      <Text.H1 css={{ ml: "$3" }}>
        Comments
        <Tooltip
          css={{ verticalAlign: "super" }}
          onOpen={() => {
            trackTooltip("Comments");
          }}
          trigger={
            <Text.Small
              css={{
                ml: "$1",
                mt: "-10px",
                color: "$grey1",
                cursor: "pointer",
              }}
            >
              <Icon icon={Icons.Question} />
            </Text.Small>
          }
          content={
            <Text.Small css={{ color: "$neutral8" }}>
              <Text.Paragraphs text={Tooltips.COMMENTS} />
            </Text.Small>
          }
        />
      </Text.H1>
      <HStack css={{ gap: "$3" }}>
        <CommentsSearch
          onChange={(value) => {
            value = trimChars(trimChars(value, " "), "\n");
            onSearchChange(value.length >= MIN_SEARCH_LENGTH ? value : null);
          }}
        />

        <CommentsFilter
          touchpoints={touchpoints}
          overall={overall}
          // experienceThemes={experienceThemes}
          onChange={(filter) => {
            onFilterChange(filter);
          }}
        />
        <CommentsDateOption
          {...dateRange}
          onChange={(value) => {
            onDateChange(value);
          }}
        />
      </HStack>
    </HStack>
  );
};

const CommentsSearch = ({ onChange }) => {
  return (
    <HStack css={{ alignItems: "center", gap: "$2", marginTop: "-8px" }}>
      <Icon
        css={{ fontSize: "25px", color: "$ppForest", marginRight: "-40px" }}
        icon={Icons.Search}
      />
      <Input.Text
        css={{
          background: "transparent",
          width: "160px",
          border: "none",
          borderBottom: "2px solid $ppForest",
          height: "42px",
          fontSize: "$2",
          // backgroundColor: "white",
          paddingLeft: "40px",
          "&::placeholder": {
            // color: "$ppForest",
            color: "$grey3",
          },
        }}
        placeholder="Search"
        onChange={onChange}
      />
    </HStack>
  );
};

const applyFilters = (comments, searchTerm, filter) => {
  return controlledFilter(searchFilter(comments, searchTerm), filter);
};

const searchFilter = (comments, searchTerm) => {
  if (!searchTerm || searchTerm < MIN_SEARCH_LENGTH) {
    return [...comments];
  }
  return comments.filter((c) => {
    return c.comment.toLowerCase().includes(searchTerm.toLowerCase());
  });
};

const controlledFilter = (comments, filter) => {
  if (!filter) {
    return [...comments];
  }
  const filterHash = {};
  filter.touchpoints.forEach((t) => {
    filterHash[t.value] = true;
  });
  return comments.filter((c) => {
    if (c.touchpoint.name === "N/A") {
      return filter.overall;
    }
    return filterHash[c.touchpoint.id] && filter.includeTouchpoints;
  });
};

const CommentsResults = ({ searchTerm, filter, dateRange }) => {
  const { venue } = React.useContext(VenueContext) as any;
  const { account } = React.useContext(AccountContext) as any;

  const [comments, setComments] = useState(null);
  const [filteredComments, setFilteredComments] = useState(null);

  const commentsAPICall = async () => {
    const result = await api.comments({
      accountId: account.shortId,
      venueId: venue.id,
      ...dateRange,
    });
    const comms = result.Comments.map((c) => {
      return {
        id: Math.random(),
        date: new Date(c.Timestamp),
        comment: c.Comment,
        surveyQuestion: c.Question,
        touchpoint: {
          id: c.Touchpoint.Id,
          name: c.Touchpoint.Name,
        },
        touchpointName: c.Touchpoint.Name,
      };
    });
    setComments(comms);
  };

  useEffect(() => {
    setFilteredComments(null);
    setComments(null);
    commentsAPICall();
  }, [venue, dateRange]);

  useEffect(() => {
    if (comments) {
      const filtered = applyFilters(comments, searchTerm, filter);
      setFilteredComments(filtered);
    }
  }, [comments, searchTerm, filter]);

  return (
    <Loader
      css={{ padding: "30px", boxSizing: "border-box" }}
      data={filteredComments}
      content={() => {
        return (
          <Container
            css={{ width: "100%", maxHeight: "100%", overflow: "auto" }}
          >
            {filteredComments.length === 0 ? (
              <NoData
                css={{ width: "100%", p: "$6" }}
                title="No Comments"
                message="No comments match the provided search criteria"
              />
            ) : (
              <Table.Simple
                headers={[
                  { label: "Comment", sortMethod: sortByField("comment") },
                  {
                    label: "Date",
                    sortMethod: (a, b, ascending) => {
                      if (ascending) {
                        return a.date.getTime() - b.date.getTime();
                      } else {
                        return b.date.getTime() - a.date.getTime();
                      }
                    },
                  },
                  {
                    label: "Survey Question",
                    sortMethod: sortByField("surveyQuestion"),
                  },
                  {
                    label: "Touchpoint",
                    sortMethod: sortByField("touchpointName"),
                  },
                ]}
                defaultSort={1}
                defaultAscending={false}
                items={filteredComments}
                itemKey={(item) => {
                  return item.id;
                }}
                itemRenderer={(item) => {
                  return [
                    item.comment,
                    formatDate(item.date),
                    item.surveyQuestion,
                    item.touchpoint.name.toLowerCase() === "n/a"
                      ? "Overall (Centre Manager)"
                      : item.touchpoint.name,
                  ];
                }}
              />
            )}
          </Container>
        );
      }}
    />
  );
};

export default Comments;
