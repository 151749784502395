import React from "react";
import { CSS } from "@stitches/react";

import { styled } from "@pp/lib/ui/theme";

// const VRule = styled("span", {
//   width: "1px",
//   backgroundColor: "$lowContrast",
//   display: "block",
// });

// const HRule = styled("hr", {
//   border: "none",
//   height: "1px",
//   backgroundColor: "$lowContrast",
// });

// export { HRule, VRule };
const Select = styled("select", {
  padding: "0.5rem",
  border: "1px solid $grey3",
  borderRadius: "0.25rem",
  backgroundColor: "white",
  color: "$ppForest",
  "&:focus": {
    outline: "none",
    borderColor: "$ppForest",
  },
  "&:hover": {
    borderColor: "$ppForest",
  },
});

const SelectNative = ({
  items,
  onChange = (value: any) => {},
  css = {},
}: {
  items: Array<{ id?: string; value: any; label: string }>;
  onChange: (value: any) => void;
  css?: CSS;
}) => {
  return (
    <Select
      css={css}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    >
      {items.map(({ id, value, label }) => {
        if (!id) {
          id = value;
        }
        return (
          <option key={value} value={value}>
            {label}
          </option>
        );
      })}
    </Select>
  );
};

export default SelectNative;
