import React from "react";
import { Loader } from "@pp/lib/ui/components";
import WidgetPanel from "../shared/WidgetPanel";
import TouchpointLineGraph from "./TouchpointLineGraph";
import Tooltips from "@pp/ui/widgets/shared/tooltips";

const TouchpointTrend = ({ touchpoint, detail }) => {
  return (
    <WidgetPanel
      title={`${touchpoint.label} Trend`}
      tooltip={Tooltips.TOUCHPOINT_TREND}
      tooltipAnalyticEvent={"touchpoint_trend"}
    >
      <Loader
        css={{ padding: "30px", boxSizing: "border-box" }}
        data={detail}
        content={() => {
          return <TouchpointLineGraph touchpoint={touchpoint} />;
        }}
      />
    </WidgetPanel>
  );
};

export default TouchpointTrend;
