import React from "react";
import Link from "./Link";

const Breadcrumbs = ({ items }) => {
  return items.map(({ label, to }, ind) => {
    return (
      <React.Fragment key={ind}>
        {to === undefined ? <span>{label}</span> : <Link to={to}>{label}</Link>}
        {ind < items.length - 1 && <span> &nbsp;&nbsp;&gt;&nbsp;&nbsp; </span>}
      </React.Fragment>
    );
  });
};

export default Breadcrumbs;
