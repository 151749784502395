import React from "react";
import {
  AccountContext,
  VenueContext,
  UserContext,
} from "@pp/ui/app/GlobalContexts";

class Analytics {
  static silent: boolean = true;
  static log: boolean = false;

  static debug() {
    this.silent = true;
    this.log = true;
  }

  static activate() {
    this.silent = false;
    this.log = false;
  }

  static track(event: string, data: any = {}) {
    if (this.log) {
      console.info("Analytics.track\n", event, data);
    }
    if (!this.silent) {
      if (typeof globalThis.gtag === "function") {
        globalThis.gtag("event", event, data);
      }
    }
  }

  static useLaunch() {
    const { account } = React.useContext(AccountContext) as any;
    const { user } = React.useContext(UserContext) as any;
    React.useEffect(() => {
      Analytics.track("launch", {
        account: account.shortId,
        user: user.id,
      });
    }, []);
  }

  static useScreenView(screenName: string, fields = null) {
    const { account } = React.useContext(AccountContext) as any;
    const { user } = React.useContext(UserContext) as any;
    const { venue } = React.useContext(VenueContext) as any;

    React.useEffect(
      () => {
        const data = {
          account: account.shortId,
          user: user.id,
          venue: venue.id,
        };
        Analytics.track(`screen_view: ${screenName}`, data);
      },
      fields === null ? [account, venue] : fields
    );
  }

  static useTrackEvent(event: string) {
    const { account } = React.useContext(AccountContext) as any;
    const { user } = React.useContext(UserContext) as any;
    const { venue } = React.useContext(VenueContext) as any;

    return (data: any = {}) => {
      const eventData = {
        account: account.shortId,
        user: user.id,
        venue: venue.id,
        ...data,
      };
      Analytics.track(event, eventData);
    };
  }

  static useTrackLink() {
    const method = Analytics.useTrackEvent("link_clicked");
    return (source: string) => {
      method({ source });
    };
  }

  static useTrackTooltip() {
    const method = Analytics.useTrackEvent("show_tooltip");
    return (context: string) => {
      method({ context });
    };
  }

  static useWidgetToggle(context: string) {
    const method = Analytics.useTrackEvent(`widget_toggle: ${context}`);
    return (type: string) => {
      method({ type });
    };
  }

  static useWidgetTab(context: string) {
    const method = Analytics.useTrackEvent(`widget_tab: ${context}`);
    return (value: string) => {
      method({ value });
    };
  }
}

globalThis.VoiceAnalytics = Analytics;

export default Analytics;
