import React from "react";
import { VStack, Text } from "@pp/lib/ui/components";

import DistributionGraph from "../shared/DistributionGraph";

const TouchpointStackedBarGraph = ({ touchpoint, footerLink = null }) => {
  const data = touchpoint.data.current.map(({ date, distribution }) => {
    const dist: { [key: number]: number } = { 60: 0 };
    Object.entries(distribution).forEach(([key, value]: [string, number]) => {
      const keyV = parseInt(key);
      if (keyV <= 60) {
        dist[60] += value;
      } else {
        dist[key] = value;
      }
    });
    return { date, distribution: dist };
  });

  return (
    <VStack css={{ flex: "1", height: "100%" }}>
      <DistributionGraph
        data={data}
        distributionValues={[60, 70, 80, 90, 100]}
        tooltipTitle={`${touchpoint.label} Score Distribution`}
        tooltipLabels={["60 and Below", "70", "80", "90", "100"]}
      />
      {footerLink && (
        <Text.Small
          css={{
            color: "$ppForest",
            textAlign: "right",
            textDecoration: "underline",
            alignSelf: "flex-end",
          }}
        >
          {footerLink}
        </Text.Small>
      )}
    </VStack>
  );
};

export default TouchpointStackedBarGraph;
