import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import * as echarts from "echarts/core";
import { BarChart } from "echarts/charts";

import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  DataZoomComponent,
} from "echarts/components";

import { CanvasRenderer } from "echarts/renderers";

import { Box, Text, NoData, ChartWrapper } from "@pp/lib/ui/components";

import colours from "@pp/lib/ui/theme/colours";
import { EChartsOption } from "echarts"; // Type for ECharts options

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent,
  DataZoomComponent,
]);

const BarGraph = ({
  title,
  labels,
  values = [],
  counts,
  total,
  series = [],
  mode = BarGraph.MODE_HORIZONTAL,
  customOptions,
  customStyle = {},
}: {
  title?: string;
  labels: string[];
  values?: number[];
  counts: number[];
  total: number;
  mode: string;
  series: Array<{
    name: string;
    values: number[];
    color?: string;
    barWidth?: number;
  }>;
  customOptions: EChartsOption;
  customStyle: React.CSSProperties;
}) => {
  if (values.length === 0 && series.length === 0) {
    return <NoData />;
  }

  var option = {
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      top: "2%",
      containLabel: true,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      formatter: (parameters) => {
        const ind = parameters[0].dataIndex;
        const label = labels[ind];
        const value = values[ind];
        const count = counts[ind];

        return renderToStaticMarkup(
          <>
            <Text.H4>{label}</Text.H4>
            <Text>Count: {count}</Text>
            <br />
            <Text>Total: {total}</Text>
            <br />
            <Text>Percentage: {value}%</Text>
          </>
        );
      },
    },

    xAxis: {
      type: "value",
    },
    yAxis: [
      {
        type: "category",
        data: labels,
      },
    ],

    series:
      series.length > 0
        ? series.map((s) => ({
            name: s.name,
            type: "bar",
            data: s.values,
            barWidth: s.barWidth || 6, // Default barWidth to 6 if not specified
            itemStyle: {
              color: s.color || colours.tealGradients, // Default color if not specified
            },
          }))
        : {
            type: "bar",
            data: values,
            barWidth: 4,
            itemStyle: {
              color: colours.ppForest,
            },
          },
  };

  if (mode === BarGraph.MODE_VERTICAL) {
    (option.yAxis as any) = {
      type: "value",
    };
    (option.xAxis as any) = {
      type: "category",
      data: labels,
    };
  }

  return (
    <Box
      css={{
        boxSizing: "border-box",
        width: "100%",
        height: "100%",
      }}
    >
      {title && (
        <Text.H4 css={{ width: "100%", textAlign: "center", height: "10%" }}>
          {title}
        </Text.H4>
      )}
      <ChartWrapper
        option={{ ...option, ...customOptions }}
        style={{
          width: "100%",
          height: title === undefined ? "100%" : "90%",
          ...(customStyle ?? {}),
        }}
      />
    </Box>
  );
};

BarGraph.MODE_HORIZONTAL = "horizontal";
BarGraph.MODE_VERTICAL = "vertical";

export default BarGraph;
