import React from "react";

export const VenueContext = React.createContext<{ venue?; setVenue? }>({});
export const TimeRangeContext = React.createContext<{
  timeRange?;
  setTimeRange?;
}>({});
export const AccountContext = React.createContext<{
  account?;
  setAccount?;
}>({});
export const BenchmarkContext = React.createContext<{
  benchmark?;
  setBenchmark?;
}>({});
export const UserContext = React.createContext<{
  user?;
}>({});
export const VenueGroupContext = React.createContext<{
  venueGroup?;
  setVenueGroup?;
}>({});

export const GlobalProvider = ({
  defaultTimeRange,
  defaultAccount,
  defaultVenue,
  defaultBenchmark,
  user,
  children = null,
}) => {
  const [timeRange, setTimeRange] = React.useState(defaultTimeRange);
  const [account, setAccount] = React.useState(defaultAccount);
  const [venue, setVenue] = React.useState(defaultVenue);
  const [benchmark, setBenchmark] = React.useState(defaultBenchmark);
  const [venueGroup, setVenueGroup] = React.useState(null);

  return (
    <TimeRangeContext.Provider value={{ timeRange, setTimeRange }}>
      <AccountContext.Provider value={{ account, setAccount }}>
        <VenueContext.Provider value={{ venue, setVenue }}>
          <BenchmarkContext.Provider value={{ benchmark, setBenchmark }}>
            <UserContext.Provider value={{ user }}>
              <VenueGroupContext.Provider value={{ venueGroup, setVenueGroup }}>
                {children}
              </VenueGroupContext.Provider>
            </UserContext.Provider>
          </BenchmarkContext.Provider>
        </VenueContext.Provider>
      </AccountContext.Provider>
    </TimeRangeContext.Provider>
  );
};
