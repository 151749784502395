import React from "react";
import * as echarts from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  VStack,
  Swatch,
  Text,
  Box,
  HStack,
  ChartWrapper,
  NoData,
} from "@pp/lib/ui/components";
import colours from "@pp/lib/ui/theme/colours";

// Register the required components
echarts.use([PieChart, CanvasRenderer]);

const cols = [
  colours.tealGradients[0],
  colours.ppForest,
  colours.tealGradients[2],
  colours.greys.grey1,
  colours.tealGradients[4],
  colours.greys.grey3,
];

const DonutChart = ({ data, radii = ["65%", "90%"], chartCSS = {} }) => {
  if (data.length === 0) {
    return <NoData />;
  }

  const option = {
    series: [
      {
        type: "pie",
        radius: radii,
        label: {
          show: false,
        },
        data: data.map((d, i) => {
          return {
            value: d.score,
            name: d.label,
            itemStyle: {
              color: cols[i % cols.length],
            },
          };
        }),
      },
    ],
  };

  return (
    <HStack
      css={{
        flex: "1",
      }}
    >
      <VStack css={{ gap: "$6", flex: "1", overflowY: 'auto' }}>
        {data.map((item, ind) => {
          return data.length <= 4 ? (
            <DonutLabel
              key={ind}
              label={item.label}
              score={item.score}
              change={item.change}
              color={cols[ind % cols.length]}
            />
          ) : (
            <DonutLabelSlim
              key={ind}
              label={item.label}
              score={item.score}
              change={item.change}
              color={cols[ind % cols.length]}
            />
          );
        })}
        <Box css={{ flex: "1" }} />
      </VStack>
      <Box
        css={{
          position: "relative",
          width: "60%",
          height: "100%",
          ...chartCSS,
        }}
      >
        <ChartWrapper option={option} />
      </Box>
    </HStack>
  );
};

const DonutLabel = ({ label, score, change, color }) => {
  return (
    <VStack
      css={{
        paddingBottom: "$2",
        borderBottom: `3px solid ${color}`,
        gap: "$1",
      }}
    >
      <Text.H4
        css={{
          fontSize: "12px",
          color: "$ppForest",
        }}
      >
        {label}
      </Text.H4>
      <HStack css={{ gap: "$1", alignItems: "end" }}>
        <Text.H1 css={{}}>{score.toFixed(0)}</Text.H1>
        <Text.H4 css={{ marginBottom: "6px" }}>%</Text.H4>
        {!isNaN(change) && <Swatch.ScoreChange change={change} />}
      </HStack>
    </VStack>
  );
};

const DonutLabelSlim = ({ label, score, change, color }) => {
  return (
    <VStack
      css={{
        borderBottom: `3px solid ${color}`,
        gap: "$1",
      }}
    >
      <HStack>
        <Text.H4
          css={{
            fontSize: "12px",
            color: "$ppForest",
            flex: "1",
          }}
        >
          {label}
        </Text.H4>
        <HStack css={{ gap: "$1", alignItems: "end", alignSelf: "end" }}>
          <Text.H2 css={{}}>{score.toFixed(0)}</Text.H2>
          <Text.H4 css={{ marginBottom: "6px" }}>%</Text.H4>
          {!isNaN(change) && <Swatch.ScoreChange change={change} />}
        </HStack>
      </HStack>
    </VStack>
  );
};

export default DonutChart;
