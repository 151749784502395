import React from "react";
import { styled, keyframes } from "@stitches/react";

const inputStyles = {
  all: "unset",
  boxSizing: "border-box",
  display: "inline-flex",
  fontSize: "$3",
  backgroundColor: "$background",
  border: "1px solid $grey3",
  "&[disabled]": {
    color: "$contrast2",
    pointerEvents: "none",
  },
  // "&:focus":{
  //   backgroundColor: "$background",
  // }
};

const InputRaw = styled("input", {
  ...inputStyles,
  padding: "$1",
});

const InputText = ({
  css = {},
  defaultValue = "",
  onChange,
  disabled = false,
  placeholder = "",
}) => {
  return (
    <InputRaw
      css={css}
      defaultValue={defaultValue}
      disabled={disabled}
      placeholder={placeholder}
      onChange={(event) => {
        onChange(event.currentTarget.value);
      }}
    />
  );
};

const InputNumber = ({
  defaultValue = null,
  onChange = null,
  css = null,
  forcePositive = false,
  forceInteger = false,
  disabled = false,
}) => {
  return (
    <InputRaw
      type="number"
      css={{ textAlign: "right", ...css }}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={(event) => {
        let num = parseFloat(event.currentTarget.value);
        if (!isNaN(num)) {
          if (forcePositive && num < 0) {
            num = 0;
          }
          if (forceInteger) {
            num = Math.round(num);
          }
          onChange(num);
        }
      }}
    />
  );
};

const Input = {
  Text: InputText,
  Number: InputNumber,
};

export default Input;
