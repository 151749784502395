import React from "react";
import { styled } from "@pp/lib/ui/theme";

const listStyles = {
  paddingInlineStart: "$5",
};

const UL = styled("ul", listStyles);
const OL = styled("ol", listStyles);
const LI = styled("li", {});

const Unorderd = ({ items, css = {}, itemCSS = {} }) => {
  return (
    <UL css={css}>
      {items.map((item, ind) => {
        return (
          <LI css={itemCSS} key={ind}>
            {item}
          </LI>
        );
      })}
    </UL>
  );
};

const Ordered = ({ items, css = {}, itemCSS = {} }) => {
  return (
    <OL css={css}>
      {items.map((item, ind) => {
        return (
          <LI css={itemCSS} key={ind}>
            {item}
          </LI>
        );
      })}
    </OL>
  );
};

const List = {
  Unorderd,
  Ordered,
};

export default List;
