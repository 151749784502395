import React, { useState, useEffect } from "react";
import * as echarts from "echarts/core";
import ReactEChartsCore from "echarts-for-react/lib/core";

const ChartWrapper = ({ option, style = {} }) => {
  const [chart, setChart] = useState(null);
  const [size, setSize] = useState({ width: 0, height: 0 });
  useEffect(() => {
    if (chart) {
      chart.resize();
    }
  }, [size]);
  window.addEventListener("resize", () => {
    setSize({ width: window.innerWidth, height: window.innerHeight });
  });

  return (
    <ReactEChartsCore
      option={option}
      echarts={echarts}
      notMerge={true}
      lazyUpdate={false}
      style={{ width: "100%", height: "100%", ...style }}
      onChartReady={(chart) => {
        setChart(chart);
      }}
    />
  );
};

export default ChartWrapper;
