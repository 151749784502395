// Dashboard
import NPSTrend from "./dashboard/nps-trend/NPSTrend";
import CollectionStatistics from "./dashboard/collection-statistics/CollectionStatistics";
import TouchpointsOverview from "./dashboard/touchpoints-overview/TouchpointsOverview";
import ThemesOverview from "./dashboard/themes-overview/ThemesOverview";
import Suggestions from "./dashboard/suggestions/Suggestions";

// Touchpoints
import TouchpointScore from "./touchpoints/TouchpointScore";
import TouchpointTrend from "./touchpoints/TouchpointTrend";
import TouchpointDetail from "./touchpoints/TouchpointDetail";
import TouchpointDetailBreakdown from "./touchpoints/TouchpointDetailBreakdown";

// Shared
import CommentsPreview from "./shared/CommentsPreview";
import CommentsAnalysis from "./shared/CommentsAnalysis";

export {
  NPSTrend,
  CollectionStatistics,
  TouchpointsOverview,
  ThemesOverview,
  Suggestions,
  TouchpointScore,
  TouchpointTrend,
  TouchpointDetail,
  TouchpointDetailBreakdown,
  CommentsPreview,
  CommentsAnalysis,
};
