import React from "react";

import { Panel, HStack, VStack, Text, Select, Icon } from "./index";

import Icons from "../app/Icons";

const SimpleSelect = ({ selected = null, options, onChange, css = {} }) => {
  if (!selected) {
    selected = options[0];
  }
  return (
    <Select
      css={css}
      trigger={() => {
        return <SelectTrigger selected={selected} />;
      }}
      content={(onClose) => {
        return (
          <SimpleSelectOptions
            options={options}
            onChange={onChange}
            onClose={onClose}
          />
        );
      }}
    />
  );
};

const SelectTrigger = ({ selected }) => {
  return (
    <HStack
      css={{
        cursor: "pointer",
        color: "$ppForest",
        borderBottom: "2px solid $ppForest",
        padding: "$1",
        width: "300px",
        boxSizing: "border-box",
        justifyContent: "space-between",
      }}
    >
      <Text.H3 css={{}}>{selected.label}</Text.H3>
      <Icon
        icon={Icons.ChevronDown}
        css={{
          fontSize: "30px",
          display: "inline-block",
        }}
      />
    </HStack>
  );
};

const SimpleSelectOptions = ({
  options,
  onChange,
  onClose,
  css = {},
}: {
  options: Array<{ id; label }>;
  onChange: (option: { id; label }) => void;
  onClose: () => void;
  css?: any;
}) => {
  return (
    <Panel
      css={{
        p: "0",
        position: "absolute",
        top: "40px",
        width: "300px",
        zIndex: "100",
        ...css,
      }}
    >
      <VStack css={{ maxHeight: "500px", overflowY: "auto" }}>
        {options.map((o) => {
          return (
            <Text
              onClick={() => {
                onClose();
                onChange(o);
              }}
              key={o.id}
              css={{
                cursor: "pointer",
                p: "$2",
                color: "$grey2",
                "&:hover": {
                  backgroundColor: "$teal3",
                  color: "$ppForest",
                },
              }}
            >
              {o.label}
            </Text>
          );
        })}
      </VStack>
    </Panel>
  );
};

export { SimpleSelectOptions };

export default SimpleSelect;
