import React, { useEffect } from "react";
import { Layout, HStack, Text, Loader } from "@pp/lib/ui/components";
import api from "@pp/lib/net/api-service";
import Analytics from "@pp/utils/analytics";
import {
  AccountContext,
  VenueContext,
  TimeRangeContext,
} from "@pp/ui/app/GlobalContexts";

import WidgetPanel from "@pp/ui/widgets/shared/WidgetPanel";
import BarGraph from "@pp/ui/widgets/shared/BarGraph";
import DonutChart from "@pp/ui/widgets/shared/DonutChart";

const processProfileResults = ([statistics, profiles]) => {
  const mapCategory = (category) =>
    category?.map(({ Count, Label }) => ({ count: Count, label: Label })) ?? [];

  return {
    gender: mapCategory(statistics?.Gender),
    age: mapCategory(statistics?.Ages),
    householdType: mapCategory(statistics?.HouseholdType),
    attendedWith: mapCategory(profiles.AttendedWith),
    visitFrequency: mapCategory(profiles.VisitFrequency),
    visitDuration: mapCategory(profiles.VisitDuration),
    spendAmount: mapCategory(profiles.SpendAmount),
    visitReasons: mapCategory(profiles.VisitReasons),
    hearAboutCentre: mapCategory(profiles.hearAboutCentre),
    preferredCentre: mapCategory(profiles.preferredCentre),
  };
};

const Profiles = () => {
  Analytics.useScreenView("customer-profiles");

  const { account } = React.useContext(AccountContext) as any;
  const { venue } = React.useContext(VenueContext) as any;
  const { timeRange } = React.useContext(TimeRangeContext) as any;

  const [profiles, setProfiles] = React.useState(null);

  useEffect(() => {
    const callAPI = async () => {
      setProfiles(null);
      const promises = [
        api.statistics({
          accountId: account.id,
          venueIds: [venue.surveyId || venue.id],
          ...timeRange,
        }),
        api.profiles({
          accountId: account.id,
          venueId: venue.surveyId || venue.id,
          ...timeRange,
        }),
      ];
      const [statistics, profiles] = await Promise.all(promises);
      setProfiles(processProfileResults([statistics, profiles]));
    };
    callAPI();
  }, [venue, timeRange]);

  return (
    <Layout.Container>
      <HStack
        css={{ width: "100%", pt: "$4", justifyContent: "space-between" }}
      >
        <Text.H2 css={{ ml: "$3" }}>Customer Profiles</Text.H2>
      </HStack>
      <Loader
        data={profiles}
        css={{ width: "100%", height: "100%" }}
        content={() => <ProfilesMain profiles={profiles} />}
      />
    </Layout.Container>
  );
};

const ProfilesMain = ({ profiles }) => {
  const { venue } = React.useContext<{ venue? }>(VenueContext) as any;
  const profilesConfig = venue.uiConfig?.Pages.CustomerProfiles;

  return (
    <>
      {profilesConfig?.Widgets.GenderBreakdown?.Display && (
        <Layout.Panel width={2} height={2}>
          <DonutGraphWidget title="Gender" data={profiles.gender} />
        </Layout.Panel>
      )}
      {profilesConfig?.Widgets.AgeBreakdown?.Display && (
        <Layout.Panel width={1} height={2}>
          <BarGraphWidget
            title="Age Breakdown"
            data={profiles.age.toReversed()}
          />
        </Layout.Panel>
      )}
      {profilesConfig?.Widgets.HouseholdType?.Display && (
        <Layout.Panel width={2} height={2}>
          <DonutGraphWidget
            title="Household Type"
            data={profiles.householdType}
          />
        </Layout.Panel>
      )}
      {profilesConfig?.Widgets.AttendedWithOthers?.Display && (
        <Layout.Panel width={1} height={2}>
          <BarGraphWidget
            title="Attended with Others"
            data={profiles.attendedWith?.toReversed()}
          />
        </Layout.Panel>
      )}
      {profilesConfig?.Widgets.VisitationFrequency?.Display && (
        <Layout.Panel width={2} height={2}>
          <DonutGraphWidget
            title="Visitation Frequency"
            data={profiles.visitFrequency}
          />
        </Layout.Panel>
      )}
      {profilesConfig?.Widgets.VisitDuration?.Display && (
        <Layout.Panel width={1} height={2}>
          <BarGraphWidget
            title="Visit Duration"
            data={profiles.visitDuration}
          />
        </Layout.Panel>
      )}

      {profilesConfig?.Widgets.Spend?.Display && (
        <Layout.Panel width={1} height={2}>
          <BarGraphWidget
            title="Spend"
            data={profiles.spendAmount}
            // mode={BarGraph.MODE_VERTICAL}
          />
        </Layout.Panel>
      )}
      {profilesConfig?.Widgets.PurposeOfVisit?.Display && (
        <Layout.Panel width={1} height={2}>
          <BarGraphWidget
            title="Purpose of Visit"
            data={profiles.visitReasons}
          />
        </Layout.Panel>
      )}
      {profilesConfig?.Widgets.KnowledgeOfCentre?.Display && (
        <Layout.Panel width={1} height={2}>
          <BarGraphWidget
            title="Knowledge of Centre"
            data={profiles.hearAboutCentre?.toReversed()}
          />
        </Layout.Panel>
      )}
      {profilesConfig?.Widgets.PreferredCentre?.Display && (
        <Layout.Panel width={1} height={2}>
          <BarGraphWidget
            title="Preferred Centre"
            data={profiles.preferredCentre}
          />
        </Layout.Panel>
      )}
    </>
  );
};

const BarGraphWidget = ({ title, data, mode = BarGraph.MODE_HORIZONTAL }) => {
  const labels = [];
  const values = [];
  const counts = [];

  const total = data.reduce((acc, { count }) => acc + count, 0);

  data.forEach(({ count, label }) => {
    labels.push(label);
    values.push(((count / total) * 100).toFixed(1));
    counts.push(count);
  });
  return (
    <WidgetPanel title={title}>
      <BarGraph
        labels={labels}
        values={values}
        counts={counts}
        total={total}
        mode={mode}
      />
    </WidgetPanel>
  );
};

const DonutGraphWidget = ({ title, data }) => {
  const total = data.reduce((acc, curr) => acc + curr.count, 0);
  data = data.map(({ count, label }) => {
    return {
      count,
      label,
      score: Math.round((count / total) * 100),
    };
  });
  return (
    <WidgetPanel title={title}>
      <DonutChart
        data={data}
        radii={["100%"]}
        chartCSS={{ marginLeft: "30px", marginTop: "-30px" }}
      />
    </WidgetPanel>
  );
};

export default Profiles;
