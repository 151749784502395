import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import * as echarts from "echarts/core";
import { BarChart } from "echarts/charts";

import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import {
  HStack,
  VStack,
  NoData,
  Box,
  ChartWrapper,
  Text,
} from "@pp/lib/ui/components";
import GraphKey from "../shared/GraphKey";
import GraphTooltip from "../shared/GraphTooltip";
import colours from "@pp/lib/ui/theme/colours";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent,
]);

const ScoreBarChart = ({
  scores,
  keyLabel,
  tooltipFormatter = null,
  footerLink = null,
}) => {
  const labels = [];
  const values = [];
  const previous = [];

  let previousLabel = null;
  if (scores.length > 1) {
    previousLabel = "Previous Month";
    if (scores[0].data.previous.length >= 12) {
      previousLabel = "Previous Year";
    }
  }

  scores.forEach((tp) => {
    labels.push(tp.label);
    values.push(tp.score);
    previous.push(tp.previousScore);
  });

  // Sometimes there will be no previous values, so don't display the  key at the bottom
  const displayPrevious = previous.filter((p) => !isNaN(p)).length > 0;

  if (values.length === 0) {
    return <NoData />;
  }

  return (
    <VStack css={{ flex: "1" }}>
      <BarGraph
        labels={labels}
        values={values}
        previous={previous}
        tooltipFormatter={tooltipFormatter}
      />
      <HStack css={{ ml: "$4", mt: "$4", gap: "$3" }}>
        <GraphKey.Rectangle color={colours.ppForest} label={keyLabel} />
        {previousLabel && displayPrevious && (
          <GraphKey.Rectangle
            color={colours.greys.grey1}
            label={previousLabel}
          />
        )}
        {footerLink && (
          <Text.Small
            css={{
              color: "$ppForest",
              textAlign: "right",
              alignSelf: "flex-end",
              flex: 1,
            }}
          >
            {footerLink}
          </Text.Small>
        )}
      </HStack>
    </VStack>
  );
};

const BarGraph = ({ labels, values, previous, tooltipFormatter }) => {
  var option = {
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      top: "0%",
      containLabel: true,
    },
    tooltip: {
      formatter: (params) => {
        if (typeof tooltipFormatter === "function") {
          return tooltipFormatter({
            label: labels[params.dataIndex],
            current: values[params.dataIndex],
            previous: previous[params.dataIndex],
          });
        }
        return null;
      },
      borderColor: "#FFFFFF",
    },
    xAxis: {
      type: "value",
    },
    yAxis: {
      type: "category",
      data: labels,
    },
    series: [
      {
        type: "bar",
        data: values,
        barWidth: 4,
        itemStyle: {
          color: colours.ppForest,
        },
      },
      {
        type: "bar",
        data: previous,
        barWidth: 4,
        itemStyle: {
          color: colours.greys.grey1,
        },
      },
    ],
  };

  return (
    <Box
      css={{
        boxSizing: "border-box",
        width: "100%",
        height: "100%",
      }}
    >
      <ChartWrapper option={option} style={{ width: "100%", height: "90%" }} />
    </Box>
  );
};

const tooltipHelper = () => {
  return ({ label, current, previous }) => {
    const items = [
      {
        label: (
          <GraphKey.Circle color={colours.ppForest} label={"Report Month"} />
        ),
        value: current.toFixed(1) + "%",
      },
    ];
    if (!isNaN(previous)) {
      items.push({
        label: (
          <GraphKey.Circle
            color={colours.greys.grey1}
            label="Previous Period"
          />
        ),
        value: previous.toFixed(1) + "%",
      });
    }

    return renderToStaticMarkup(
      <GraphTooltip title={label} items={[...items]} />
    );
  };
};

ScoreBarChart.tooltipHelper = tooltipHelper;

export default ScoreBarChart;
