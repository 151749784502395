import React from "react";
import { useLocation } from "react-router-dom";
import {
  Panel,
  Box,
  HStack,
  VStack,
  Text,
  Select,
  Icon,
  SimpleSelectOptions,
} from "@pp/lib/ui/components";
import Icons from "@pp/lib/ui/app/Icons";
import { styled } from "@pp/lib/ui/theme";
import {
  VenueContext,
  TimeRangeContext,
  AccountContext,
  BenchmarkContext,
  VenueGroupContext,
  CustomQuestionContext,
} from "@pp/ui/app/GlobalContexts";
import {
  doTimes,
  MONTHS_FULL,
  MONTHS,
  getDefaultTimeRange,
} from "@pp/lib/utils";

import { uniqBy } from "lodash";

import Analytics from "@pp/utils/analytics";

const NO_BENCHMARK = { label: "No Benchmark", id: null, value: null };
const ALL_VENUES = { label: "All Venues", id: null, value: null };

const Header = ({ user }) => {
  const location = useLocation();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div style={{ display: "flex", gap: "$1" }}>
        {user.accounts.length > 1 && <AccountSelect user={user} />}
        {location.pathname === "/portfolio" && (
          <VenueGroupSelect location={location} />
        )}
        {location.pathname !== "/portfolio" && (
          <>
            <VenueSelect user={user} /> <Box css={{ flex: "1" }} />
          </>
        )}
      </div>
      <div
        style={{
          display: "flex",
          gap: "8px",
        }}
      >
        <ComparisonMetrics user={user} />
      </div>
    </div>
  );
};

const AccountSelect = ({ user }) => {
  const { account, setAccount } = React.useContext<{ account?; setAccount? }>(
    AccountContext
  ) as any;
  const { setVenue } = React.useContext<{ setVenue? }>(VenueContext);
  const { benchmark, setBenchmark } = React.useContext<{
    benchmark?;
    setBenchmark?;
  }>(BenchmarkContext);

  const handleAccountChange = (value) => {
    const venue = value.venues[0];
    setAccount(value);
    setVenue(venue);

    // Attempt to keep the benchmark the same if it exists on the new venue
    const currentBenchmark = venue.benchmarks.find((b) => {
      return b.id === benchmark?.id;
    });
    if (!currentBenchmark) {
      setBenchmark(NO_BENCHMARK);
    }
  };

  return (
    <VStack
      css={{
        ml: "$2",
        mt: "$2",
        gap: "$1",
        mr: "$4",
        borderBottom: "2px solid $ppForest",
      }}
    >
      <Text.H4
        css={{
          fontSize: "10px",
          fontWeight: "bold",
          color: "$ppForest",
          textTransform: "uppercase",
        }}
      >
        Account
      </Text.H4>

      <Select
        trigger={() => {
          return (
            <HStack css={{ cursor: "pointer" }}>
              <Text.H3>{account.name}</Text.H3>
              <Icon
                icon={Icons.ChevronDown}
                css={{
                  fontSize: "20px",
                  display: "inline-block",
                  paddingLeft: "30px",
                }}
              />
            </HStack>
          );
        }}
        content={(onClose) => {
          return (
            <SimpleSelectOptions
              options={user.accounts.map(({ id, name }) => {
                return { id, label: name };
              })}
              onClose={onClose}
              onChange={(value) => {
                const account = user.accounts.find((a) => a.id === value.id);
                handleAccountChange(account);
              }}
            />
          );
        }}
      />
    </VStack>
  );
};

const VenueGroupSelect = ({ location }) => {
  const { account } = React.useContext<{ account? }>(AccountContext) as any;

  const { venueGroup, setVenueGroup } = React.useContext<{
    venueGroup?;
    setVenueGroup?;
  }>(VenueGroupContext) as any;

  if (!account?.venueGroups || location.pathname !== "/portfolio") {
    return null;
  }

  const handleVenueGroupChange = (value) => {
    setVenueGroup(value);
  };

  return (
    <VStack
      css={{
        ml: "$2",
        mt: "$2",
        gap: "$1",
        borderBottom: "2px solid $ppForest",
      }}
    >
      <Text.H4
        css={{
          fontSize: "10px",
          fontWeight: "bold",
          color: "$ppForest",
          textTransform: "uppercase",
        }}
      >
        Venue Group
      </Text.H4>

      <Select
        trigger={() => {
          return (
            <HStack css={{ cursor: "pointer" }}>
              <Text.H3>
                {venueGroup ? venueGroup.label : ALL_VENUES.label}
              </Text.H3>
              <Icon
                icon={Icons.ChevronDown}
                css={{
                  fontSize: "20px",
                  display: "inline-block",
                  paddingLeft: "30px",
                }}
              />
            </HStack>
          );
        }}
        content={(onClose) => {
          return (
            <SimpleSelectOptions
              options={[
                ALL_VENUES,
                ...account.venueGroups.map(({ id, label, venues }) => {
                  return { id, label, venues };
                }),
              ]}
              onClose={onClose}
              onChange={(value) => {
                handleVenueGroupChange(value);
              }}
            />
          );
        }}
      />
    </VStack>
  );
};

const VenueSelect = ({ user }) => {
  const { account } = React.useContext(AccountContext) as any;
  const { venue, setVenue } = React.useContext(VenueContext) as any;

  const { benchmark, setBenchmark } = React.useContext<{
    benchmark?;
    setBenchmark?;
  }>(BenchmarkContext);

  const handleVenueChange = (value) => {
    setVenue(value);
    // Attempt to keep the benchmark the same if it exists on the new venue
    const currentBenchmark = value.benchmarks.find((b) => {
      return b.id === benchmark?.id;
    });
    if (!currentBenchmark) {
      setBenchmark(NO_BENCHMARK);
    }
  };

  return (
    <VStack
      css={{
        ml: "$2",
        mt: "$2",
        gap: "$1",
        borderBottom: "2px solid $ppForest",
      }}
    >
      <Text.H4
        css={{
          fontSize: "10px",
          fontWeight: "bold",
          color: "$ppForest",
          textTransform: "uppercase",
        }}
      >
        Venue
      </Text.H4>

      <Select
        trigger={() => {
          return (
            <HStack css={{ cursor: "pointer" }}>
              <Text.H3>{venue.name}</Text.H3>
              <Icon
                icon={Icons.ChevronDown}
                css={{
                  fontSize: "20px",
                  display: "inline-block",
                  paddingLeft: "30px",
                }}
              />
            </HStack>
          );
        }}
        content={(onClose) => {
          return (
            <SimpleSelectOptions
              options={account.venues.map(({ id, name }) => {
                return { id: id, label: name };
              })}
              onClose={onClose}
              onChange={(value) => {
                const venue = account.venues.find((v) => v.id === value.id);
                handleVenueChange(venue);
              }}
            />
          );
        }}
      />
    </VStack>
  );
};

const ComparisonMetrics = ({ user }) => {
  const { timeRange, setTimeRange } = React.useContext(TimeRangeContext) as any;
  const { benchmark, setBenchmark } = React.useContext(BenchmarkContext) as any;
  const { venueGroup } = React.useContext(VenueGroupContext) as any;
  const { account } = React.useContext(AccountContext) as any;
  const { venue } = React.useContext(VenueContext) as any;
  const { questionsType } = React.useContext(CustomQuestionContext) as any;
  const [selectedRange, setSelectedRange] = React.useState(timeRange.to);
  const trackMonthChange = Analytics.useTrackEvent("month_change");
  const trackBenchmarkChange = Analytics.useTrackEvent("benchmark_change");

  const location = useLocation();
  // Disable the time selection component on the comments page.
  if (location.pathname.indexOf("/comments") === 0) {
    return null;
  }

  const defaultTimeRange = getDefaultTimeRange();
  const lastMonth = new Date(defaultTimeRange.to);

  const items = doTimes(11, (i) => {
    const prevMonth = new Date(defaultTimeRange.to);
    prevMonth.setDate(1);
    prevMonth.setMonth(prevMonth.getMonth() - i + 1);
    prevMonth.setDate(0);
    return prevMonth;
  });

  const filteredBenchmarks =
    location.pathname.startsWith("/portfolio") &&
    account?.portfolioBenchmarkIds?.length
      ? uniqBy(
          (venueGroup?.id
            ? account.venues?.filter(({ id }) => venueGroup.venues.includes(id))
            : account.venues
          )?.flatMap(({ benchmarks }) => benchmarks) ?? [],
          "id"
        ).filter((bmrk) =>
          account?.portfolioBenchmarkIds?.includes(
            (bmrk as unknown as { id: string; label: string }).id as string
          )
        )
      : [...venue.benchmarks];
  const handleTimeRangeChange = (value) => {
    setSelectedRange(value);

    const to = value;
    const from = new Date(to.getFullYear(), to.getMonth() - 11, 1);
    // from.setFullYear(from.getFullYear() - 1);
    // from.setDate(from.getDate() + 1);
    const newTimeRange = { from, to };
    setTimeRange(newTimeRange);
    trackMonthChange({ month: value.toISOString().substring(0, 7) });
  };

  const handleBenchmarkChange = (value) => {
    setBenchmark(value);
    trackBenchmarkChange({ benchmark: value.id });
  };

  const dateLabel = (date): string => {
    const str = `${MONTHS_FULL[date.getMonth()]}  ${date.getFullYear()}`;
    return date.getFullYear() === lastMonth.getFullYear() &&
      date.getMonth() === lastMonth.getMonth()
      ? `Last Month`
      : str;
  };

  return (
    <>
      {venue.benchmarks.length > 0 &&
        questionsType?.id !== "historical-questions" &&
        filteredBenchmarks?.length > 0 && (
          <Select
            css={{ zIndex: 100 }}
            trigger={() => {
              return (
                <SelectTrigger
                  icon={Icons.Scale}
                  title="Benchmark"
                  selection={(benchmark || NO_BENCHMARK).label}
                />
              );
            }}
            content={(onClose) => {
              return (
                <SimpleSelectOptions
                  options={[NO_BENCHMARK, ...(filteredBenchmarks ?? [])]}
                  onClose={onClose}
                  onChange={handleBenchmarkChange}
                  css={{
                    top: "60px",
                  }}
                />
              );
            }}
          />
        )}
      {questionsType?.id !== "historical-questions" && (
        <Select
          css={{ zIndex: 100 }}
          trigger={() => {
            return (
              <SelectTrigger
                icon={Icons.Clock}
                title="Report Month"
                selection={dateLabel(selectedRange)}
              />
            );
          }}
          content={(onClose) => {
            return (
              <DateOptions
                onSelect={(value) => {
                  onClose();
                  handleTimeRangeChange(value);
                }}
              />
            );
          }}
        />
      )}
    </>
  );
};

const SelectTrigger = ({ icon, title, selection }) => {
  return (
    <HStack
      css={{
        cursor: "pointer",
        backgroundColor: "$grey0",
        color: "$ppForest",
        borderBottom: "2px solid $ppForest",
        padding: "$1",
        width: "300px",
        boxSizing: "border-box",
      }}
    >
      <Icon
        icon={icon}
        css={{ fontSize: "30px", display: "inline-block", mx: "$1" }}
      />
      <VStack css={{ gap: "$1", flex: "1" }}>
        <Text.Small css={{ color: "$grey2" }}>{title}</Text.Small>
        <Text.H3>{selection}</Text.H3>
      </VStack>
      <Icon
        icon={Icons.ChevronDown}
        css={{
          fontSize: "30px",
          display: "inline-block",
          top: "15px",
        }}
      />
    </HStack>
  );
};

const DateOptions = ({ onSelect }) => {
  const defaultTimeRange = getDefaultTimeRange();
  const lastMonth = new Date(defaultTimeRange.to);

  const [year, setYear] = React.useState(lastMonth.getFullYear());
  const [month, setMonth] = React.useState(lastMonth.getMonth());

  const monthButton = (index) => {
    const disabled =
      defaultTimeRange.to.getFullYear() === year &&
      index > defaultTimeRange.to.getMonth();
    const lastMonth =
      defaultTimeRange.to.getFullYear() === year &&
      index === defaultTimeRange.to.getMonth();

    const color = lastMonth ? "$ppForest" : "$grey3";
    const bgColour = lastMonth ? "$teal2" : "transparent";

    return (
      <Text
        css={{
          width: "25%",
          color: color,
          backgroundColor: bgColour,
          borderRadius: "3px",
          cursor: disabled ? "default" : "pointer",
          p: "$1",
          textAlign: "center",
          opacity: disabled ? 0.5 : 1,
          "&:hover": {
            backgroundColor: "$teal3",
          },
        }}
        onClick={() => {
          if (!disabled) {
            setMonth(index);
            const date = new Date(year, parseInt(index) + 1, 0);
            onSelect(date);
          }
        }}
      >
        {MONTHS[index]}
      </Text>
    );
  };

  const enableRight = year < defaultTimeRange.to.getFullYear();

  return (
    <Panel
      css={{
        position: "absolute",
        top: "60px",
        width: "300px",
        p: "$3",
        zIndex: 100,
      }}
    >
      <VStack css={{ gap: "$4" }}>
        <HStack css={{ color: "$ppForest" }}>
          <Icon
            icon={Icons.ChevronLeft}
            css={{ fontSize: "$6", cursor: "pointer" }}
            onClick={() => {
              setYear(year - 1);
            }}
          />
          <Text
            css={{
              flex: 1,
              textAlign: "center",
              fontSize: "$4",
              lineHeight: "$6",
            }}
          >
            {year}
          </Text>
          <Icon
            icon={Icons.ChevronRight}
            css={{
              fontSize: "$6",
              color: !enableRight ? "$grey2" : "$ppForest",
              cursor: !enableRight ? "default" : "pointer",
            }}
            onClick={() => {
              if (enableRight) {
                setYear(year + 1);
              }
            }}
          />
        </HStack>
        <HStack>
          {monthButton(0)}
          {monthButton(1)}
          {monthButton(2)}
          {monthButton(3)}
        </HStack>
        <HStack>
          {monthButton(4)}
          {monthButton(5)}
          {monthButton(6)}
          {monthButton(7)}
        </HStack>
        <HStack>
          {monthButton(8)}
          {monthButton(9)}
          {monthButton(10)}
          {monthButton(11)}
        </HStack>
      </VStack>
    </Panel>
  );
};

const StyledHeader = styled(Header);

export default StyledHeader;
