import React from "react";

import { VStack, Text, NoData } from "@pp/lib/ui/components";
import { formatDate } from "@pp/lib/utils";

const CommentStack = ({ comments }) => {

  return (
    <VStack
      css={{
        flex: "1",
        maxHeight: "100%",
        borderTop: "2px solid $grey1",
        overflowY: "auto",
        gap: "$4",
        py: "$4",
        boxSizing: "border-box",
      }}
    >
      {comments.length === 0 && <NoData />}
      {comments.length > 0 &&
        comments.map(({ text, date }, i) => {
          return (
            <VStack css={{ gap: "$1" }} key={i}>
              <Text.H6>{formatDate(date)}</Text.H6>
              <Text>{text}</Text>
            </VStack>
          );
        })}
    </VStack>
  );
};

export default CommentStack;
