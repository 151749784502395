import React, { useEffect, useState, useContext } from "react";
import {
  Layout,
  Loader,
  SimpleSelect,
  Text,
  VStack,
} from "@pp/lib/ui/components";
import {
  AccountContext,
  CustomQuestionContext,
  VenueContext,
} from "@pp/ui/app/GlobalContexts";
import { getDefaultTimeRange } from "@pp/lib/utils";
import api from "@pp/lib/net/api-service";
import { BREAKDOWN, QuestionWidget } from "../custom-questions/QuestionWidget";

const CUSTOM_QUESTIONS_TYPE_OPTIONS = [
  { id: "live-questions", label: "Live Questions" },
  { id: "historical-questions", label: "Historical Questions" },
];

const CustomQuestions = () => {
  const { account } = useContext(AccountContext);
  const { venue } = useContext(VenueContext);
  const { questionsType, setQuestionsType } = useContext(CustomQuestionContext);
  const [customQuestions, setCustomQuestions] = useState([]);
  const [activeViews, setActiveViews] = useState({});

  useEffect(() => {
    const fetchCustomQuestions = async () => {
      const defaultRange = getDefaultTimeRange();
      const result = await api.customQuestions({
        accountId: account.shortId,
        venueId: venue.id,
        ...defaultRange,
      });

      setCustomQuestions(result);
      setActiveViews(
        result.reduce((acc, item) => ({ ...acc, [item.id]: BREAKDOWN }), {})
      );
    };

    fetchCustomQuestions();
  }, [account.shortId, venue.id]);
  return (
    <Layout.ContainerFluid>
      <VStack css={{ width: "100%", pt: "$4", marginLeft: "10px", gap: "6px" }}>
        <Text.H2 css={{ ml: "$1" }}>Custom Questions</Text.H2>
        <SimpleSelect
          css={{ zIndex: 50, mb: "$2" }}
          selected={questionsType ?? null}
          options={CUSTOM_QUESTIONS_TYPE_OPTIONS}
          onChange={(val) => setQuestionsType(val)}
        />
        <Loader
          css={{ width: "100%", height: "100%" }}
          data={customQuestions?.length}
          content={() => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {customQuestions.map((item) => (
                  <QuestionWidget
                    item={item}
                    key={item.qid + item.netAgreement}
                    activeViews={activeViews}
                    setActiveViews={setActiveViews}
                  />
                ))}
              </div>
            );
          }}
        />
      </VStack>
    </Layout.ContainerFluid>
  );
};

export default CustomQuestions;
