import React from "react";
import { Panel, Box } from "@pp/lib/ui/components";

const Header = ({ children = null, css = {} }) => {
  return (
    <>
      <Panel
        css={{
          position: "fixed",
          width: "calc($full - $navCollapsedWidth)",
          height: "calc($fullHeaderHeight)",
          left: "$navCollapsedWidth",
          zIndex: 100,
          justifyContent: "space-between",
          flexDirection: "row",
          display: "flex",
          flex: "1",
          gap: "$4",
          ...css,
        }}
      >
        {children}
      </Panel>
      {/* Block because other elements are position:fixed */}
      <Box css={{ height: "calc($fullHeaderHeight)" }} />
    </>
  );
};

export default Header;
