import React from "react";

export const DialogContext = React.createContext(null);

interface IDialog extends React.Component {
  onClose: () => void;
}

const DialogManager = ({ children = null }: { children?: React.Node }) => {
  const [dialog, setDialog]: [
    { component: IDialog },
    ({ component }: { component: IDialog }) => void
  ] = React.useState(null);
  const [dialogProps, setDialogProps] = React.useState({});

  const showDialog = (dialog: IDialog, props = {}) => {
    setDialog({ component: dialog });
    setDialogProps(props || {});
  };

  const closeDialog = () => {
    setDialog(null);
    setDialogProps(null);
  };

  return (
    <DialogContext.Provider
      value={{
        showDialog,
        closeDialog,
      }}
    >
      {dialog !== null &&
        React.createElement(dialog.component, {
          onClose: closeDialog,
          ...dialogProps,
        })}
      {children}
    </DialogContext.Provider>
  );
};

DialogManager.useDialogs = () => {
  return React.useContext(DialogContext);
};

export default DialogManager;

// import React from "react";

// export const DialogContext = React.createContext(null);

// interface IDialog extends React.Component {
//   NAME: string;
// }

// const DialogManager = ({
//   dialogs,
//   children = null,
// }: {
//   dialogs: Array<IDialog>;
//   children: React.Node;
// }) => {
//   const [openDialog, setOpenDialog] = React.useState(null);
//   const [dialogProps, setDialogProps] = React.useState(null);

//   const showDialog = (dialog, props) => {
//     setOpenDialog(dialog.NAME);
//     setDialogProps(props);
//   };

//   const showDialogByName = (name, props) => {
//     const dialog = dialogs.find((d) => d.NAME === name);
//     if (dialog) {
//       showDialog(dialog, props);
//     }
//   };

//   const closeDialog = () => {
//     setOpenDialog(null);
//     setDialogProps(null);
//   };

//   return (
//     <DialogContext.Provider
//       value={{
//         showDialog,
//         showDialogByName,
//       }}
//     >
//       {dialogs.map((dialog) => {
//         const { NAME } = dialog;
//         return React.createElement(dialog, {
//           key: NAME,
//           open: openDialog === NAME,
//           onClose: closeDialog,
//           ...dialogProps,
//         });
//       })}
//       {children}
//     </DialogContext.Provider>
//   );
// };

// DialogManager.useDialogs = () => {
//   return React.useContext(DialogContext);
// };

// export default DialogManager;
