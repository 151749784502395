import React from "react";
import { Routes, Route } from "react-router-dom";
import { VStack, Text, Redirect } from "@pp/lib/ui/components";
import { AppLayout, Icons } from "@pp/lib/ui/app";
import { getDefaultTimeRange } from "@pp/lib/utils";
import Analytics from "@pp/utils/analytics";

import VoiceHeader from "./VoiceHeader";
import { GlobalProvider } from "./GlobalContexts";
import Dashboard from "./Dashboard";
import Touchpoints from "./Touchpoints";
import Comments from "../comments/Comments";
import Profiles from "./Profiles";

import { VenueContext } from "@pp/ui/app/GlobalContexts";

const inialiseAnalytics = (user) => {
  const hn = globalThis.location.hostname;

  // Only enable analytics for prod and stage environments
  if (hn.includes("voice.prescient.properties")) {
    // Always activate for stage, as it is sent to a different GA property
    if (hn.includes("stage")) {
      Analytics.activate();
    } else {
      // On prod, if the accounts is greater than 1, then it is an admin account for pp employees.  Therefore disable collection
      if (user.accounts.length === 1) {
        Analytics.activate();
      } else {
        Analytics.debug();
      }
    }
  } else {
    Analytics.debug();
  }
};

const App = ({ user }) => {
  if (user.accounts.length === 0 || user.accounts[0].venues.length === 0) {
    return <NoVenues />;
  }
  let account = user.accounts[0];
  let venue = account.venues[0];
  // if (globalThis.location.hostname.includes("localhost")) {
  //   account = user.accounts[2];
  //   venue = account.venues[0];
  // }

  let timeRange = getDefaultTimeRange();

  // When using a demo account, force the date range.
  if (user.email.includes("+demo@prescient.properties")) {
    const from = new Date("2023-06-01");
    const to = new Date("2024-06-30");
    timeRange = { from, to };
  }

  inialiseAnalytics(user);

  return (
    <GlobalProvider
      defaultTimeRange={timeRange}
      defaultAccount={account}
      defaultVenue={venue}
      defaultBenchmark={null}
      user={user}
    >
      <MainApp user={user} />
    </GlobalProvider>
  );
};

const MainApp = ({ user }) => {
  const { venue } = React.useContext<{ venue? }>(VenueContext) as any;

  const pageConfig = venue.uiConfig?.Pages;
  const navItems = [];

  pageConfig?.Dashboard?.Display &&
    navItems.push({
      icon: Icons.House,
      label: "Voice Summary",
      route: "/dashboard",
    });

  pageConfig?.Touchpoints?.Display &&
    navItems.push({
      icon: Icons.Network,
      label: "Touchpoints",
      route: "/touchpoints",
    });

  pageConfig?.Comments?.Display &&
    navItems.push({
      icon: Icons.Comment,
      label: "Comments",
      route: "/comments",
    });

  pageConfig?.CustomerProfiles?.Display &&
    navItems.push({
      icon: Icons.People,
      label: "Customer Profiles",
      route: "/profiles",
    });

  navItems.push({
    icon: Icons.Question,
    label: "Support",
    route: "mailto:hello@prescient.properties",
  });

  return (
    <AppLayout
      user={user}
      navItems={navItems}
      headerContent={<VoiceHeader user={user} />}
      mainContent={<MainContent />}
    />
  );
};

const MainContent = () => {
  Analytics.useLaunch();

  return (
    <Routes>
      <Route path="/" element={<Redirect to="/dashboard" />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/touchpoints" element={<Touchpoints />} />
      <Route path="/comments" element={<Comments />} />
      <Route path="/profiles" element={<Profiles />} />
    </Routes>
  );
};

const NoVenues = () => {
  return (
    <VStack css={{ padding: "50px" }}>
      <Text.H1>No Venues</Text.H1>
      <Text.H2>
        There are no venues associated with your account. Please contact your
        administrator.
      </Text.H2>
    </VStack>
  );
};

export default App;
