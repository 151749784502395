import React from "react";
import {
  Panel,
  ChartWrapper,
  VStack,
  Box,
  Text,
  Swatch,
  Loader,
} from "@pp/lib/ui/components";
import WidgetPanel from "../shared/WidgetPanel";
import colours from "@pp/lib/ui/theme/colours";
import * as echarts from "echarts/core";
import { GaugeChart } from "echarts/charts";

import {
  TooltipComponent,
  TitleComponent,
  LegendComponent,
} from "echarts/components";

import { CanvasRenderer } from "echarts/renderers";

import GraphKey from "../shared/GraphKey";
import GraphTooltip from "../shared/GraphTooltip";

import { TimeRangeContext } from "@pp/ui/app/GlobalContexts";
import { MONTHS_FULL } from "@pp/lib/utils";
import Tooltips from "@pp/ui/widgets/shared/tooltips";

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GaugeChart,
  CanvasRenderer,
]);

const TouchpointScore = ({ touchpoint, detail }) => {
  const [displayTooltip, setDisplayTooltip] = React.useState(false);

  const score = Math.round(touchpoint.score);
  const change = touchpoint.change;
  const fontSize = score > 99 ? "80px" : "100px";
  const marginTop = score > 99 ? "10px" : "0px";
  const paddingBottom = score > 99 ? "18px" : "28px";
  const marginLeft = change === 0 ? "40px" : "0px";

  return (
    <WidgetPanel
      title={`${touchpoint.label} Score`}
      tooltip={Tooltips.TOUCHPOINT_SCORE}
      tooltipAnalyticEvent={"touchpoint_score"}
    >
      <Loader
        css={{ padding: "30px", boxSizing: "border-box" }}
        data={detail}
        content={() => {
          return (
            <VStack>
              <Box
                css={{ position: "relative", width: "100%", height: "100%" }}
              >
                <Box
                  css={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "50%",
                    marginTop: "-65px",
                  }}
                >
                  <Text.H2
                    css={{
                      fontSize,
                      color: colours.ppForest,
                      textAlign: "center",
                      ml: marginLeft,
                      mt: marginTop,
                    }}
                  >
                    {score}
                    <Swatch.ScoreChange
                      css={{
                        fontSize: "20px",
                        paddingBottom,
                        display: "inline-block",
                        verticalAlign: "bottom",
                      }}
                      change={change}
                    />
                  </Text.H2>
                </Box>
                <div
                  style={{ width: "100%", height: "100%" }}
                  onMouseOver={() => setDisplayTooltip(true)}
                  onMouseOut={() => {
                    setDisplayTooltip(false);
                  }}
                >
                  <GaugeGraph touchpoint={touchpoint} />
                  <ToolTip touchpoint={touchpoint} display={displayTooltip} />
                </div>
              </Box>

              <GraphKey.Set
                keys={[
                  {
                    label: `${touchpoint.label} Score`,
                    color: colours.ppForest,
                  },
                  {
                    label: "Previous Period",
                    color: colours.greys.grey1,
                  },
                  {
                    label: "Benchmark",
                    color: colours.greys.grey15,
                  },
                ]}
              />
            </VStack>
          );
        }}
      />
    </WidgetPanel>
  );
};

const GaugeGraph = ({ touchpoint }) => {
  const gaugeData = [
    {
      value: touchpoint.score,
      name: `${touchpoint.label} Score`,
      title: {
        show: false,
      },
      detail: {
        show: false,
      },
      itemStyle: {
        color: colours.ppForest,
      },
    },
    {
      value: touchpoint.previousScore,
      name: "Previous Period",
      title: {
        show: false,
      },
      detail: {
        show: false,
      },
      itemStyle: {
        color: colours.greys.grey1,
      },
    },
    {
      value: touchpoint.benchmark.current,
      name: "Benchmark",
      title: {
        show: false,
      },
      detail: {
        show: false,
      },
      itemStyle: {
        color: colours.greys.grey15,
      },
    },
  ];
  const option = {
    series: [
      {
        type: "gauge",
        radius: "90%",
        min: 0,
        max: 100,
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false,
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: false,
          clip: false,
          itemStyle: {},
        },
        axisLine: {
          show: true,
          lineStyle: {
            width: 40,
            color: [[1, colours.greys.grey0]],
          },
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        data: gaugeData,
        title: {
          show: false,
          fontSize: 14,
        },
      },
    ],
  };

  const [chart, setChart] = React.useState(null);
  const [size, setSize] = React.useState({ width: 0, height: 0 });
  React.useEffect(() => {
    if (chart) {
      chart.resize();
    }
  }, [size]);
  window.addEventListener("resize", function () {
    setSize({ width: window.innerWidth, height: window.innerHeight });
  });

  return <ChartWrapper option={option} />;
};

const ToolTip = ({ touchpoint, display = false }) => {
  if (!display) {
    return null;
  }

  const { timeRange } = React.useContext(TimeRangeContext);

  let monthLabel = MONTHS_FULL[timeRange.to.getMonth()];
  const items = [];

  items.push({
    label: (
      <GraphKey.Rectangle
        color={colours.ppForest}
        label={`${touchpoint.label} Score`}
      />
    ),
    value: (
      <>
        {Math.round(touchpoint.score)}
        <Swatch.ScoreChange change={touchpoint.change} />
      </>
    ),
  });
  items.push({
    label: (
      <>
        <GraphKey.Rectangle
          color={colours.greys.grey1}
          label="Previous Period"
        />
      </>
    ),
    value: (
      <>
        {Math.round(touchpoint.previousScore)}
        {/* The below is never displayed, it is just there for spacing */}
        <Swatch.ScoreChange change={0} />
      </>
    ),
  });
  items.push({
    label: (
      <GraphKey.Rectangle color={colours.greys.grey15} label="Benchmark" />
    ),
    value: (
      <>
        {Math.round(touchpoint.benchmark.current)}
        <Swatch.ScoreChange change={touchpoint.benchmark.change} />
      </>
    ),
  });

  return (
    <Panel css={{ position: "absolute", left: "0px", top: "0px" }}>
      <Panel.Content>
        <GraphTooltip css={{ zIndex: 100 }} title={monthLabel} items={items} />
      </Panel.Content>
    </Panel>
  );
};

export default TouchpointScore;
