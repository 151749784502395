import { Swatch } from "@pp/lib/ui/components";
import React from "react";
import LineGraphByMonth from "../widgets/shared/LineGraphByMonth";
import TouchpointLineGraph from "../widgets/touchpoints/TouchpointLineGraph";
import PortfolioBarGraph from "./PortfolioBarGraph";

export function renderSwatchSet({ data, activeMetric, setActiveMetric }) {
  return (
    <Swatch.HSet css={{ flex: "1" }}>
      {data.map(({ label, score, change, metricName }, index) => (
        <Swatch.ScoreSwatch
          key={label}
          active={activeMetric === (metricName || label)}
          onClick={() => setActiveMetric(metricName || label)}
          title={label}
          titleCSS={index === 0 ? { fontWeight: "bold" } : {}}
          score={score}
          change={change}
          formatter={(score) => {
            if (index === 0) {
              return score > 0 ? `+${score}` : `${score}`;
            }
            return score;
          }}
        />
      ))}
    </Swatch.HSet>
  );
}

export function renderGraph({
  entityKey,
  viewTypes,
  data,
  barGraphData,
  categories,
  activeTouchpoint,
  fullData,
  benchmarkData,
}: any) {
  const isTotalPortfolio = viewTypes[entityKey] === "total_portfolio";

  if (entityKey === "touchpoint") {
    const activePoint =
      activeTouchpoint || fullData?.aggregatedTouchpoints?.[0];
    return isTotalPortfolio ? (
      <TouchpointLineGraph touchpoint={activePoint} />
    ) : (
      <PortfolioBarGraph
        values={barGraphData}
        category={Object.keys(fullData?.touchpointVenueBreakdown || {})}
      />
    );
  }

  if (isTotalPortfolio) {
    return (
      <LineGraphByMonth
        data={data}
        style={{ height: "350px" }}
        benchmark={benchmarkData}
        zeroBased={true}
      />
    );
  }

  return <PortfolioBarGraph values={barGraphData} category={categories} />;
}
