import React from "react";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { HRule } from "./Rule";
import { styled, keyframes } from "../theme";

const overlayShow = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const contentShow = keyframes({
  "0%": { opacity: 0, transform: "translate(-50%, -48%) scale(.96)" },
  "100%": { opacity: 1, transform: "translate(-50%, -50%) scale(1)" },
});

const DialogOverlay = styled(AlertDialog.Overlay, {
  backgroundColor: "$overlayBackground",
  position: "fixed",
  inset: 0,
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  zIndex: 1000,
});

const DialogContent = styled(AlertDialog.Content, {
  backgroundColor: "$background",
  borderRadius: 6,
  boxShadow:
    "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  maxWidth: "500px",
  padding: 25,
  animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  zIndex: 1001,
  "&:focus": { outline: "none" },
  "@bp1": {
    maxHeight: "90%",
    overflowY: "auto",
  },
});

const DialogTitle = styled(AlertDialog.Title, {
  margin: 0,
  fontSize: "$3.5",
  fontFamily: "$feature",
});

const DialogDescription = styled(AlertDialog.Description, {
  marginBottom: 20,
  fontSize: 15,
  lineHeight: 1.5,
});

const DialogButtons = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  columnGap: "$1",
});

const Dialog = ({ title = null, children = null, open = false }) => {
  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Portal>
        <DialogOverlay />
        <DialogContent>
          {title && (
            <>
              <DialogTitle>{title}</DialogTitle>
              <HRule css={{ my: "$2" }}></HRule>
            </>
          )}

          {children}
        </DialogContent>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
};

Dialog.Root = AlertDialog.Root;
Dialog.Portal = AlertDialog.Portal;
Dialog.Overlay = DialogOverlay;
Dialog.Content = DialogContent;

Dialog.Title = DialogTitle;
Dialog.Description = DialogDescription;
Dialog.Buttons = DialogButtons;

export default Dialog;
