const DEFAULT_PERCENTAGE_SCALE = 100;

const processScore = (
  idName: string,
  currentData,
  previousData,
  benchmarkData,
  currentYearRange,
  previousYearRange
) => {
  let delta = 0;
  let change = 0;
  let score = 0;
  let previousScore = 0;

  if (currentData.length === 0) {
    return { score, delta, change, data: { current: [], previous: [] } };
  }

  score = currentData[currentData.length - 1].Score;
  if (currentData.length >= 2) {
    let prev = currentData[currentData.length - 2].Score;
    if (previousData?.length === 12) {
      prev = previousData[previousData.length - 1].Score;
    }
    delta = Number(score.toFixed()) - Number(prev.toFixed());
    change = delta / DEFAULT_PERCENTAGE_SCALE;
    previousScore = prev;
  }

  const current = currentData.map(({ Score, Date: d, Distribution }) => {
    const c: {
      value: number;
      date: Date;
      distribution?: { [key: number]: number };
    } = {
      value: Score,
      date: new Date(d),
    };
    if (Distribution) {
      c.distribution = Distribution;
    }
    return c;
  });
  let previous = [];
  if (previousData) {
    previous = previousData.map(({ Score, Date: d }) => {
      return {
        value: Score,
        date: new Date(d),
      };
    });
  }

  // Sometimes the previous data won't go back as far as the current data.
  // So we need to add empty data points to the start of the previous data so that the lines on the graph are aligneds.
  // while (previous.length < current.length) {
  //   previous.unshift({});
  // }

  // Find the benchmarks for each of the data points by matching the dates.
  const benchmarks = benchmarkData?.map(({ date, data }) => {
    if (!data) {
      return null;
    }
    const benchmark = data[idName];
    if (!benchmark) {
      return null;
    }
    return {
      date,
      value: benchmark.score,
    };
  });

  const currentBenchmark = benchmarks[benchmarks.length - 1];
  const previousBenchmark = benchmarks[benchmarks.length - 2];
  const benchmarkChange =
    (currentBenchmark?.value - previousBenchmark?.value) /
    previousBenchmark?.value;

  let filledPreviousYearData = [];
  let filledCurrentYearData = [];
  let filledBenchmarksYearData = [];
  if (current?.length && currentYearRange) {
    filledCurrentYearData = fillMissingMonths(currentYearRange, current);
  }
  if (previous?.length && previousYearRange) {
    filledPreviousYearData = fillMissingMonths(previousYearRange, previous);
  }
  if (benchmarks?.length && currentYearRange) {
    filledBenchmarksYearData = fillMissingMonths(currentYearRange, benchmarks);
  }

  return {
    delta,
    change,
    score,
    previousScore,
    data: {
      current: filledCurrentYearData?.length ? filledCurrentYearData : current,
      previous: filledPreviousYearData?.length
        ? filledPreviousYearData
        : previous,
    },
    benchmark: {
      current: currentBenchmark?.value || 0,
      change: benchmarkChange || 0,
      data: filledBenchmarksYearData?.length
        ? filledBenchmarksYearData
        : benchmarks,
    },
  };
};

function fillMissingMonths(range, data) {
  const filledData = [];
  const start = new Date(range.from);
  const end = new Date(range.to);

  // Create a Set for existing year-month combinations for quick lookup
  const existingMonths = new Set(
    data.map((d) => {
      if (!d) return undefined;
      const date = new Date(d.date);
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}`;
    })
  );

  // Iterate through each month in the range
  while (start <= end) {
    const year = start.getFullYear();
    const month = String(start.getMonth() + 1).padStart(2, "0"); // 1-indexed for string comparison
    const key = `${year}-${month}`;

    // Push existing data or a placeholder
    filledData.push(
      existingMonths.has(key)
        ? data.find((d) => {
            const dDate = new Date(d?.date);
            return (
              dDate.getFullYear() === year &&
              dDate.getMonth() === start.getMonth()
            );
          })
        : { date: new Date(year, start.getMonth(), 10), value: null }
    );

    // Move to the next month
    start.setMonth(start.getMonth() + 1);
  }

  return filledData;
}

const processNpsScore = (
  dataPoints,
  prevDataPoints,
  benchmarkData,
  benchmarkKey,
  currentYearRange,
  previousYearRange
) => {
  let delta = 0;
  let change = 0;
  let score = 0;

  if (dataPoints.length >= 1) {
    score = dataPoints[dataPoints.length - 1].Value;
    if (isNaN(score)) {
      score = 0;
    }
    if (dataPoints.length >= 2) {
      let prev = dataPoints[dataPoints.length - 2].Value;
      if (prevDataPoints?.length === 12) {
        prev = prevDataPoints[prevDataPoints.length - 1].Value;
      }
      if (!isNaN(prev) && prev !== 0) {
        delta = Number(score.toFixed()) - Number(prev.toFixed());
        change = delta / 200; // nps scale will be from -100 to +100
      }
    }
  }

  const currentData = dataPoints.map(({ Value, Date: d }, i) => {
    const value = parseFloat(Value);
    return {
      date: new Date(d),
      value,
    };
  });
  const previousData =
    prevDataPoints?.map(({ Value, Date: d }, i) => {
      const value = parseFloat(Value);
      return {
        date: new Date(d),
        value,
      };
    }) || [];

  // Find the benchmarks for each of the data points by matching the dates.
  const benchmarks = benchmarkData?.map(({ date, data }) => {
    const scores = data?.scores;
    if (!scores) {
      return null;
    }
    return {
      date,
      value: scores[benchmarkKey]?.score,
    };
  });
  // Sometimes the previous data won't go back as far as the current data.
  // So we need to add empty data points to the start of the previous data so that the lines on the graph are aligneds.
  // while (previousData.length < currentData.length) {
  //   previousData.unshift({});
  // }
  let filledPreviousYearData = [];
  let filledCurrentYearData = [];
  let filledBenchmarksYearData = [];
  if (currentData?.length && currentYearRange) {
    filledCurrentYearData = fillMissingMonths(currentYearRange, currentData);
  }
  if (previousData?.length && previousYearRange) {
    filledPreviousYearData = fillMissingMonths(previousYearRange, previousData);
  }
  if (benchmarks?.length && currentYearRange) {
    filledBenchmarksYearData = fillMissingMonths(currentYearRange, benchmarks);
  }

  return {
    delta,
    change,
    score,
    data: {
      current: filledCurrentYearData?.length
        ? filledCurrentYearData
        : currentData,
      previous: filledPreviousYearData?.length
        ? filledPreviousYearData
        : previousData,
    },
    benchmark: {
      data: filledBenchmarksYearData?.length
        ? filledBenchmarksYearData
        : benchmarks,
    },
  };
};

const processTouchpoints = (
  current,
  previous,
  currentYearRange,
  previousYearRange
) => {
  const touchpoints = current.Touchpoints.map(
    ({ Id, Name, IdName, DataPoints }) => {
      let prev;
      if (previous) {
        prev = previous.Touchpoints.find((tp) => tp.Id === Id);
      }
      return {
        id: Id,
        label: Name,
        idName: IdName,
        ...processScore(
          IdName,
          DataPoints,
          prev?.DataPoints,
          current.BenchmarkData,
          currentYearRange,
          previousYearRange
        ),
      };
    }
  );
  return touchpoints;
};

const processAgreement = (currentData, previousData) => {
  let delta = 0;
  let change = 0;
  let agreement = currentData;
  let previousAgreement = 0;

  if (!isNaN(previousData)) {
    previousAgreement = previousData;
    delta = agreement - previousAgreement;
    change = delta / Math.abs(previousAgreement);
    if (previousAgreement === 0) {
      change = 1;
    }
  }

  return {
    delta,
    change,
    agreement,
    previousAgreement,
  };
};

const processTouchpointDetail = (current, previous) => {
  const bd = (c, p) => {
    let change = 0;
    if (!isNaN(p)) {
      const delta = c - p;
      change = delta / Math.abs(p);
      if (p === 0) {
        change = 1;
      }
    }

    return {
      value: c,
      change,
    };
  };

  const breakdown = (
    { Agree, Disagree, Neutral, StronglyAgree, StronglyDisagree },
    previousBreakdown
  ) => {
    return {
      stronglyDisagree: bd(
        StronglyDisagree,
        previousBreakdown?.StronglyDisagree
      ),
      disagree: bd(Disagree, previousBreakdown?.Disagree),
      neutral: bd(Neutral, previousBreakdown?.Neutral),
      agree: bd(Agree, previousBreakdown?.Agree),
      stronglyAgree: bd(StronglyAgree, previousBreakdown?.StronglyAgree),
    };
  };
  const questions = current.Questions.map(
    ({ Id, Label, ShortLabel, NetAgreement, Breakdown }, i) => {
      let prev;
      if (previous) {
        prev = previous.Questions.find((q) => q.Id === Id);
      }

      return {
        id: Id,
        label: Label,
        shortLabel: ShortLabel,
        netAgreement: processAgreement(NetAgreement, prev?.NetAgreement),
        breakdown: breakdown(Breakdown, prev?.Breakdown),
      };
    }
  );
  return questions;
};

const processThemeScore = (
  currentData,
  previousData,
  themeName,
  benchmarkData,
  currentYearRange,
  previousYearRange
) => {
  let delta = 0;
  let change = 0;
  let score = 0;

  if (currentData.length === 0) {
    return { score, delta, change, data: { current: [], previous: [] } };
  }

  score = currentData[currentData.length - 1].Score;
  if (currentData.length >= 2) {
    let prev = currentData[currentData.length - 2].Score;
    if (previousData?.length === 12) {
      prev = previousData[previousData.length - 1].Score;
    }
    delta = Number(score.toFixed()) - Number(prev.toFixed());
    change = delta / DEFAULT_PERCENTAGE_SCALE;
  }

  const current = currentData.map(({ Score: value, Date: d }) => {
    return {
      date: new Date(d),
      value,
    };
  });

  // Find the benchmarks for each of the data points by matching the dates.
  const benchmarks = benchmarkData?.length
    ? benchmarkData.map(({ date, data }) => {
        if (!data) {
          return null;
        }
        const benchmark = data[themeName.toLowerCase()];
        if (!benchmark) {
          return null;
        }
        return {
          date,
          value: benchmark.score,
        };
      })
    : [];

  let previous = [];
  if (previousData) {
    previous = previousData.map(({ Score: value, Date: d }) => {
      return {
        value,
        date: new Date(d),
      };
    });
  }

  // Sometimes the previous data won't go back as far as the current data.
  // So we need to add empty data points to the start of the previous data so that the lines on the graph are aligneds.
  // while (previous.length < current.length) {
  //   previous.unshift({});
  // }

  let filledPreviousYearData = [];
  let filledCurrentYearData = [];
  let filledBenchmarksYearData = [];
  if (current?.length && currentYearRange) {
    filledCurrentYearData = fillMissingMonths(currentYearRange, current);
  }
  if (previous?.length && previousYearRange) {
    filledPreviousYearData = fillMissingMonths(previousYearRange, previous);
  }
  if (benchmarks?.length && currentYearRange) {
    filledBenchmarksYearData = fillMissingMonths(currentYearRange, benchmarks);
  }

  return {
    score,
    delta,
    change,
    data: {
      current: filledCurrentYearData?.length ? filledCurrentYearData : current,
      previous: filledPreviousYearData?.length
        ? filledPreviousYearData
        : previous,
    },
    benchmarks: filledBenchmarksYearData?.length
      ? filledBenchmarksYearData
      : benchmarks,
  };
};

export const padZeros = (num: number, size: number = 1): string => {
  let s = num + "";
  while (s.length < size) {
    s = "0" + s;
  }
  return s;
};

const getYearMonthString = (date: Date): string => {
  return `${date.toLocaleString("default", {
    month: "long",
  })} ${date.getFullYear()}`;
};

export {
  processTouchpoints,
  processTouchpointDetail,
  processNpsScore,
  processThemeScore,
  getYearMonthString,
};
