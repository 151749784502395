import { Icons } from "@pp/lib/ui/app";
import {
  VStack,
  Select,
  HStack,
  Icon,
  SimpleSelectOptions,
  Text,
} from "@pp/lib/ui/components";
import React from "react";

const PortfolioWidgetsHeader = ({ entityKey, viewTypes, setViewTypes }) => {
  return (
    <VStack
      css={{
        ml: "$2",
        mr: "$1.5",
        mb: "$2",
        borderBottom: "2px solid $ppForest",
        position: "relative",
        zIndex: "1",
      }}
    >
      <Select
        trigger={() => {
          return (
            <HStack
              css={{
                cursor: "pointer",
                backgroundColor: "$grey0",
                color: "$ppForest",
                borderBottom: "0px solid $ppForest",
                padding: "4px",
                width: "200px",
                boxSizing: "border-box",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text.H4>
                {viewTypes[entityKey] === "total_portfolio"
                  ? "Total Group"
                  : "Venue Breakdown"}
              </Text.H4>
              <Icon
                icon={Icons.ChevronDown}
                css={{
                  fontSize: "30px",
                  display: "inline-block",
                  float: "right",
                }}
              />
            </HStack>
          );
        }}
        content={(onClose) => {
          return (
            <SimpleSelectOptions
              options={[
                { id: "total_portfolio", label: "Total Group" },
                { id: "venue_breakdown", label: "Venue Breakdown" },
              ]}
              onClose={onClose}
              onChange={(value) => {
                setViewTypes((prevViewTypes) => ({
                  ...prevViewTypes,
                  [entityKey]: value.id,
                }));
              }}
              css={{
                width: "200px",
              }}
            />
          );
        }}
      />
    </VStack>
  );
};

export { PortfolioWidgetsHeader };
