import React from "react";
import { Dialog, Button, VStack, Loader } from "@pp/lib/ui/components";

const ConfirmationDialog = ({
  title,
  message,
  onConfirm,
  onClose,
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
}) => {
  const [loading, setLoading] = React.useState(false);
  return (
    <Dialog title={title} open={true}>
      <VStack css={{ gap: "$4" }}>
        <Loader
          data={!loading}
          content={() => {
            return (
              <>
                {message}
                <Dialog.Buttons>
                  <Button onClick={onClose}>{cancelButtonText}</Button>
                  <Button
                    onClick={async () => {
                      setLoading(true);
                      await onConfirm();
                      setLoading(false);
                      onClose();
                    }}
                  >
                    {confirmButtonText}
                  </Button>
                </Dialog.Buttons>
              </>
            );
          }}
        />
      </VStack>
    </Dialog>
  );
};

export default ConfirmationDialog;
