import React, { useMemo } from "react";
import { colours } from "@pp/lib/ui/theme";
import BarGraph from "../widgets/shared/BarGraph";
import { Text } from "@pp/lib/ui/components";
import { renderToStaticMarkup } from "react-dom/server";

export default function PortfolioBarGraph({ values = [], category = [] }) {
  // Memoize the series data to avoid recalculating on every render
  const seriesData = useMemo(() => {
    return [
      {
        name: "Last Month",
        values: values.map((val) => val?.previousMonthScore || 0),
        color: colours.ppForest,
        barWidth: "6%",
      },
      {
        name: "Current Month",
        values: values.map((val) => val?.currentMonthScore || 0),
        color: colours.dark.positiveGreen,
        barWidth: "6%",
      },
    ];
  }, [values]);

  // Memoize the xAxis data to avoid recalculating on every render
  const xAxisData = useMemo(() => {
    return category.map((venue) =>
      venue
        ?.replace(/(?:^|-)\w/g, (match) => match.toUpperCase())
        .replace(/-/g, " ")
    );
  }, [category]);

  const customOptions = useMemo(
    () => ({
      xAxis: {
        type: "category",
        data: xAxisData,
        axisLabel: {
          interval: 0, // Ensure all labels are displayed
          fontSize: 12, // Reduce font size for better space utilization
          margin: 10,
          align: "center",
          formatter: (value) => {
            const maxChars = 15; // Maximum number of characters before truncation
            return value.length > maxChars
              ? value.substring(0, maxChars) + "..."
              : value;
          },
        },
      },
      grid: {
        top: "20px", // Adjust the top margin
        left: "50px", // Adjust the left margin
        right: "20px", // Adjust the right margin
        bottom: "40px", // Adjust the bottom margin
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: (parameters) => {
          const ind = parameters[0].dataIndex;
          const current = seriesData[1]?.values[ind];
          const previous = seriesData[0]?.values[ind];
          const label = xAxisData[ind];

          return renderToStaticMarkup(
            <>
              <Text.H4>{label}</Text.H4>
              <Text>Current: {Math.round(current)}</Text>
              <br />
              <Text>Previous: {Math.round(previous)}</Text>
            </>
          );
        },
      },
      // ...(values.length > 3
      //   ? {
      //       dataZoom: [
      //         {
      //           type: "slider",
      //           show: true,
      //           start: 0,
      //           end: 40,
      //           handleSize: "100%",
      //           height: 8,
      //           bottom: 0,
      //           showDetail: false,
      //           handleStyle: {
      //             color: "#aaa",
      //             borderColor: "#666",
      //             borderWidth: 0,
      //             opacity: 1,
      //             emphasis: {
      //               color: "#aaa",
      //               borderColor: "#666",
      //             },
      //           },
      //           dataBackground: {
      //             lineStyle: {
      //               color: "#ddd",
      //             },
      //             areaStyle: {
      //               color: "#ddd",
      //             },
      //           },
      //         },
      //       ],
      //     }
      //   : undefined),
    }),
    [xAxisData]
  );

  return (
    <BarGraph
      labels={[]}
      counts={0}
      total={20}
      mode="vertical"
      // customStyle={{ height: "250px" }}
      series={seriesData}
      customOptions={customOptions}
    />
  );
}
