import React from "react";

import { VStack, HStack, Text, Icon } from "@pp/lib/ui/components";
import Icons from "@pp/lib/ui/app/Icons";

import { DateRangePicker } from "rsuite";
import { formatAllNumberDate } from "@pp/lib/utils";

const CommentsDateOption = ({
  from,
  to,
  onChange,
}: {
  from: Date;
  to: Date;
  onChange: Function;
}) => {
  // When user clicks "Apply" Button
  const handleApply = (value: [Date, Date]) => {
    onChange({ from: value[0], to: value[1] });
  };

  const value: [Date, Date] = [from, to];

  const predefinedRanges: Array<any> = [
    {
      label: "Cancel",
      value,
      appearance: "default",
    },
  ];

  // toggle Component clicked to show Calendar
  const toggleComponent = React.forwardRef(() => {
    return (
      <HStack
        css={{
          cursor: "pointer",
          color: "$ppForest",
          padding: "$1",
          boxSizing: "border-box",
          width: "220px",
          borderBottom: "2px solid $ppForest",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "$2",
        }}
      >
        {`${formatAllNumberDate(from)} - ${formatAllNumberDate(to)}`}{" "}
        <Icon
          icon={Icons.ChevronDown}
          css={{
            fontSize: "30px",
            display: "inline-block",
          }}
        />
      </HStack>
    );
  });

  return (
    <>
      <DateRangePicker
        size="md"
        appearance="subtle"
        character=" - "
        placeholder={renderDateLabels([new Date(), new Date()])}
        placement="bottomEnd"
        cleanable={false}
        format="dd/MM/yyyy"
        value={value}
        locale={{
          sunday: "S",
          monday: "M",
          tuesday: "T",
          wednesday: "W",
          thursday: "T",
          friday: "F",
          saturday: "S",
          ok: "Apply",
        }}
        ranges={predefinedRanges}
        editable={false}
        renderTitle={renderTitle}
        renderValue={(value) => renderDateLabels(value)}
        onOk={handleApply}
        toggleAs={toggleComponent}
      />
    </>
  );
};

// Month Title like "April 2023"
const renderTitle = (date: Date) => {
  return (
    <Text.H3 css={{ color: "$ppForest", fontSize: "16px" }}>
      {date.toLocaleDateString("en-US", { year: "numeric", month: "long" })}
    </Text.H3>
  );
};

// "From Date", "To Date" like "From Date  dd/MM/yyyy"
const renderDateLabels = (dates: Date[]) => {
  return (
    <HStack
      css={{
        color: "$ppForest",
        padding: "$1",
        boxSizing: "border-box",
        gap: "$2",
      }}
    >
      {renderDate("From Date", dates[0])}
      {renderDate("To Date", dates[1])}
    </HStack>
  );
};

const renderDate = (label: String, date: Date) => {
  return (
    <VStack
      css={{
        mt: "$2",
        gap: "$1",
        flex: "1",
        borderBottom: "2px solid $grey3",
      }}
    >
      <Text.H4
        css={{
          fontSize: "12px",
          fontWeight: "bold",
          color: "$ppForest",
        }}
      >
        {label}
      </Text.H4>
      <Text.H3>{formatAllNumberDate(date)}</Text.H3>
    </VStack>
  );
};

export default CommentsDateOption;
