import React from "react";
import {
  HStack,
  Loader,
  Panel,
  ComponentSwitch,
  VStack,
} from "@pp/lib/ui/components";
import { TouchpointMenu } from "../widgets/dashboard/touchpoints-overview/TouchpointsOverview";
import WidgetPanel from "../widgets/shared/WidgetPanel";
import { PortfolioWidgetsHeader } from "./PortfolioWidgetsHeader";
import { renderGraph, renderSwatchSet } from "./PortfolioWidgetElements";
import GraphKey from "../widgets/shared/GraphKey";
import { colours } from "@pp/lib/ui/theme";
import { getYearMonthString } from "@pp/utils/data";

const PortfolioWidgetPanel = React.memo(
  (props: any) => {
    const {
      title,
      tooltip,
      tooltipEvent,
      entityKey,
      viewTypes,
      setViewTypes,
      fullData,
      activeTouchpoint,
      activeNpsMetric,
      activeThemesMetric,
      setActiveTouchpoint,
      setActiveNpsMetric,
      setActiveThemesMetric,
      npsLineGraphData,
      npsBarGraphData,
      themesLineGraphData,
      themesBarGraphData,
      venueBreakdown,
      benchmarkData,
      benchmark,
      timeRange,
    } = props;
    const currentDate = getYearMonthString(timeRange?.to);
    const previousDate = getYearMonthString(
      new Date(
        new Date(timeRange?.to).getFullYear(),
        new Date(timeRange?.to).getMonth() - 1
      )
    );
    const graphs = {
      nps: {
        data: npsLineGraphData?.data,
        barGraphData: Object.values(npsBarGraphData || {}),
        categories: Object.keys(venueBreakdown || {}),
        benchmarkData: npsLineGraphData?.benchmark?.data,
        tooltip: { label: "NPS+ Score", benchmarkLabel: benchmark?.label },
        timeRange: { currentDate, previousDate },
      },
      touchpoint: {
        data: null,
        barGraphData: venueBreakdown,
        categories: Object.keys(fullData?.touchpointVenueBreakdown || {}),
        activeTouchpoint: activeTouchpoint,
        fullData,
        benchmarkData,
        timeRange: { currentDate, previousDate },
      },
      themes: {
        data: themesLineGraphData?.data,
        barGraphData: themesBarGraphData,
        categories: Object.keys(fullData?.themesVenueBreakdown || {}),
        benchmarkData: themesLineGraphData?.benchmarks,
        tooltip: { label: "Theme Score", benchmarkLabel: benchmark?.label },
        timeRange: { currentDate, previousDate },
      },
      statistics: {
        data: fullData?.aggregatedResponseStats?.data,
        barGraphData: Object.values(venueBreakdown || {}),
        categories: Object.keys(venueBreakdown || {}),
        tooltip: { label: "Total Responses" },
        timeRange: { currentDate, previousDate },
      },
    };
    console.log("fullData", activeTouchpoint);
    return (
      <WidgetPanel
        title={title}
        tooltip={tooltip}
        tooltipAnalyticEvent={tooltipEvent}
        actions={
          <Panel.Actions>
            <PortfolioWidgetsHeader
              entityKey={entityKey}
              viewTypes={viewTypes}
              setViewTypes={setViewTypes}
            />
          </Panel.Actions>
        }
        css={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginBottom: "16px",
          height: "500px",
          paddingLeft: "16px",
        }}
      >
        <Loader
          css={{ padding: "30px", boxSizing: "border-box" }}
          data={fullData}
          content={() => {
            return (
              <HStack css={{ gap: "$4", flex: "1" }}>
                {entityKey === "touchpoint" && (
                  <TouchpointMenu
                    touchpoints={fullData?.aggregatedTouchpoints}
                    active={
                      activeTouchpoint ?? fullData?.aggregatedTouchpoints?.[0]
                    }
                    onSelect={(touchpoint) => {
                      setActiveTouchpoint(touchpoint);
                    }}
                    customCSS={{ width: "25%" }}
                  />
                )}
                <ComponentSwitch
                  tests={[
                    () =>
                      entityKey === "nps" && (
                        <VStack css={{ flex: "1" }}>
                          {renderSwatchSet({
                            data: fullData?.totalNpsPortfolio || [],
                            activeMetric: activeNpsMetric,
                            setActiveMetric: setActiveNpsMetric,
                          })}
                          {renderGraph({
                            entityKey,
                            viewTypes,
                            ...graphs.nps,
                          })}
                          <HStack css={{ ml: "$4", mt: "$4", gap: "$3" }}>
                            {viewTypes[entityKey] === "total_portfolio" ? (
                              <>
                                <GraphKey.Circle
                                  color={colours.ppForest}
                                  label={`NPS+ Score`}
                                />
                                {benchmarkData?.length ? (
                                  <GraphKey.Circle
                                    color={colours.greys.grey3}
                                    label={benchmark?.label}
                                  />
                                ) : null}{" "}
                              </>
                            ) : (
                              <>
                                <GraphKey.Circle
                                  color={colours.ppForest}
                                  label={previousDate}
                                />
                                <GraphKey.Circle
                                  color={colours.dark.positiveGreen}
                                  label={currentDate}
                                />
                              </>
                            )}
                          </HStack>
                        </VStack>
                      ),
                    () =>
                      entityKey === "touchpoint" && (
                        <VStack css={{ flex: "1" }}>
                          {renderGraph({
                            entityKey,
                            viewTypes,
                            ...graphs.touchpoint,
                          })}
                          <HStack css={{ ml: "$4", mt: "$4" }}>
                            {viewTypes[entityKey] !== "total_portfolio" ? (
                              <>
                                <GraphKey.Circle
                                  color={colours.ppForest}
                                  label={previousDate}
                                />
                                <GraphKey.Circle
                                  color={colours.dark.positiveGreen}
                                  label={currentDate}
                                />
                              </>
                            ) : null}
                          </HStack>
                        </VStack>
                      ),
                    () =>
                      entityKey === "themes" && (
                        <VStack css={{ flex: "1" }}>
                          {renderSwatchSet({
                            data: fullData?.themesData || [],
                            activeMetric: activeThemesMetric,
                            setActiveMetric: setActiveThemesMetric,
                          })}
                          {renderGraph({
                            entityKey,
                            viewTypes,
                            ...graphs.themes,
                          })}
                          <HStack css={{ ml: "$4", mt: "$4", gap: "$3" }}>
                            {viewTypes[entityKey] === "total_portfolio" ? (
                              <>
                                <GraphKey.Circle
                                  color={colours.ppForest}
                                  label={`Theme Score`}
                                />
                                {benchmarkData?.length ? (
                                  <GraphKey.Circle
                                    color={colours.greys.grey3}
                                    label={benchmark?.label}
                                  />
                                ) : null}{" "}
                              </>
                            ) : (
                              <>
                                <GraphKey.Circle
                                  color={colours.ppForest}
                                  label={previousDate}
                                />
                                <GraphKey.Circle
                                  color={colours.dark.positiveGreen}
                                  label={currentDate}
                                />
                              </>
                            )}
                          </HStack>
                        </VStack>
                      ),
                    () =>
                      entityKey === "statistics" && (
                        <VStack css={{ flex: "1" }}>
                          {renderGraph({
                            entityKey,
                            viewTypes,
                            ...graphs.statistics,
                          })}
                          <HStack css={{ ml: "$4", mt: "$4" }}>
                            {viewTypes[entityKey] === "total_portfolio" ? (
                              <GraphKey.Circle
                                color={colours.ppForest}
                                label={`Total Responses`}
                              />
                            ) : (
                              <>
                                <GraphKey.Circle
                                  color={colours.ppForest}
                                  label={previousDate}
                                />
                                <GraphKey.Circle
                                  color={colours.dark.positiveGreen}
                                  label={currentDate}
                                />
                              </>
                            )}
                          </HStack>
                        </VStack>
                      ),
                  ]}
                />
              </HStack>
            );
          }}
        />
      </WidgetPanel>
    );
  },
  (prevProps, nextProps) => {
    // Compare only relevant props for re-rendering
    return (
      prevProps.viewTypes?.[prevProps.entityKey] ===
        nextProps.viewTypes?.[prevProps.entityKey] &&
      prevProps.fullData === nextProps.fullData &&
      prevProps.activeTouchpoint === nextProps.activeTouchpoint &&
      prevProps.activeNpsMetric === nextProps.activeNpsMetric &&
      prevProps.activeThemesMetric === nextProps.activeThemesMetric &&
      prevProps.account === nextProps.account
    ); // Add more as needed
  }
);

export { PortfolioWidgetPanel };
