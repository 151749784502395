import React from "react";

import Text from "./Text";
import Box from "./Box";

const NoData = ({
  title = "No Data",
  message = "There is no data for the selected time range.",
  css = {}
}) => {
  return (
    <Box
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        color: "$lowContrast",
        ...css,
      }}
    >
      <Text.H4>{title}</Text.H4>
      <Text.Small>{message}</Text.Small>
    </Box>
  );
};

export default NoData;
