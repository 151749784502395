import React from "react";
import { styled } from "@pp/lib/ui/theme";
import Text from "./Text";
import Box from "./Box";
import Input from "./Input";
import { VStack } from "./Stack";

const TableElement = styled("table", {
  borderCollapse: "collapse",
  width: "100%",
});
const THead = styled("thead");
const TBody = styled("tbody");
const TR = styled("tr", {
  borderBottom: "1px solid $minContrast",
  "&:last-child": {
    borderBottom: "none",
  },
});
const TH = styled("th", {
  textAlign: "left",
  borderBottom: "1px solid $lowContrast",
  p: "$2",
});
const TD = styled("td", {
  p: "$2",
});

const renderExplanation = (explanation) => {
  return (
    explanation && (
      <Box css={{ py: "$1" }}>
        <Text.Small css={{ color: "$mediumContrast" }}>
          {explanation}
        </Text.Small>
      </Box>
    )
  );
};

const SimpleForm: React.FC = ({ fields }) => {
  return (
    <TableElement>
      <TBody>
        {fields.map((field, index) => {
          return (
            <TR key={index}>
              <TD
                css={{
                  verticalAlign: "top",
                  borderRight: "1px solid $minContrast",
                }}
              >
                <VStack>
                  <Text>{field.label}</Text>
                  {renderExplanation(field.explanation)}
                </VStack>
              </TD>
              <TD css={{ width: "70%" }}>{field.control}</TD>
            </TR>
          );
        })}
      </TBody>
    </TableElement>
  );
};

SimpleForm.helper = function helper<FieldValues>(original: any, fields) {
  const values: any = {};
  fields = fields.map((field) => {
    values[field.key] = original[field.key];
    let control = field.control;
    if (!control) {
      control = Input.Text;
    }

    control = React.createElement(control, {
      defaultValue: values[field.key],
      onChange: (v) => {
        values[field.key] = v;
      },
      ...field.controlProps,
    });

    return {
      label: field.label,
      control,
    };
  });

  return {
    getFormValues: (): FieldValues => {
      return { ...values } as FieldValues;
    },
    fields,
  };
};

export default SimpleForm;
