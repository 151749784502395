import React from "react";
import { Layout, Text, VStack } from "@pp/lib/ui/components";

import tooltips from "../widgets/shared/tooltips";
import { PortfolioWidgetPanel } from "../portfolio/PortfolioWidgetPanel";
import usePortfolio from "../portfolio/usePortfolio";

const Portfolio = () => {
  const {
    fullData,
    touchpointVenueBreakdown,
    activeTouchpoint,
    setActiveTouchpoint,
    viewTypes,
    setViewTypes,
    activeNpsMetric,
    setActiveNpsMetric,
    npsLineGraphData,
    npsBarGraphData,
    activeThemesMetric,
    setActiveThemesMetric,
    themesLineGraphData,
    themesBarGraphData,
    account,
  } = usePortfolio();

  const widgetConfigs = account?.uiConfig?.Pages?.Portfolio?.Widgets;

  return (
    <Layout.ContainerFluid>
      <VStack
        css={{
          width: "100%",
          pt: "$4",
          justifyContent: "space-between",
          marginLeft: "10px",
        }}
      >
        <Text.H2 css={{ ml: "$1" }}>Portfolio Report</Text.H2>
        <Text.H4 css={{ ml: "$1", mt: "$3" }}>
          Understand the total performance of your portfolio and specific venue
          groups
        </Text.H4>
      </VStack>
      {widgetConfigs?.NpsPerformance?.Display && (
        <PortfolioWidgetPanel
          title={"NPS+ Performance"}
          tooltip={tooltips.NPS_PLUS_TREND}
          tooltipEvent={"nps_trend"}
          entityKey={"nps"}
          fullData={fullData}
          viewTypes={viewTypes}
          setViewTypes={setViewTypes}
          venueBreakdown={fullData?.npsVenueBreakdown}
          activeNpsMetric={activeNpsMetric}
          setActiveNpsMetric={setActiveNpsMetric}
          npsLineGraphData={npsLineGraphData}
          npsBarGraphData={npsBarGraphData}
          account={account}
          benchmarkData={fullData?.npsBenchmarkData}
        />
      )}
      {widgetConfigs?.TouchpointPerformance?.Display && (
        <PortfolioWidgetPanel
          title={"Touchpoint Performance"}
          tooltip={tooltips.NPS_PLUS_TREND}
          tooltipEvent={"nps_trend"}
          entityKey={"touchpoint"}
          fullData={fullData}
          activeTouchpoint={activeTouchpoint}
          setActiveTouchpoint={setActiveTouchpoint}
          venueBreakdown={touchpointVenueBreakdown}
          viewTypes={viewTypes}
          setViewTypes={setViewTypes}
          account={account}
          benchmarkData={fullData?.touchpointBenchmarkData}
        />
      )}
      {widgetConfigs?.TouchpointPerformance?.Display && (
        <PortfolioWidgetPanel
          title={"Themes Performance"}
          tooltip={tooltips.NPS_PLUS_TREND}
          tooltipEvent={"nps_trend"}
          entityKey={"themes"}
          fullData={fullData}
          venueBreakdown={touchpointVenueBreakdown}
          viewTypes={viewTypes}
          setViewTypes={setViewTypes}
          activeThemesMetric={activeThemesMetric}
          setActiveThemesMetric={setActiveThemesMetric}
          themesLineGraphData={themesLineGraphData}
          themesBarGraphData={themesBarGraphData}
          account={account}
          benchmarkData={fullData?.themesBenchmarkData}
        />
      )}
      {widgetConfigs?.TouchpointPerformance?.Display && (
        <PortfolioWidgetPanel
          title={"Collection Statistics Performance"}
          tooltip={tooltips.NPS_PLUS_TREND}
          tooltipEvent={"nps_trend"}
          entityKey={"statistics"}
          fullData={fullData}
          venueBreakdown={fullData?.statVenueBreakdown}
          viewTypes={viewTypes}
          setViewTypes={setViewTypes}
          account={account}
        />
      )}
    </Layout.ContainerFluid>
  );
};
export default Portfolio;
