import React from "react";
import { Layout, HStack, Text } from "@pp/lib/ui/components";
import Analytics from "@pp/utils/analytics";
import { VenueContext } from "@pp/ui/app/GlobalContexts";

import {
  NPSTrend,
  CollectionStatistics,
  TouchpointsOverview,
  ThemesOverview,
  Suggestions,
} from "../widgets";

const Dashboard = () => {
  Analytics.useScreenView("dashboard");

  const { venue } = React.useContext<{ venue? }>(VenueContext) as any;
  const dashboardConfig = venue.uiConfig?.Pages.Dashboard;

  return (
    <Layout.Container>
      <HStack
        css={{ width: "100%", pt: "$4", justifyContent: "space-between" }}
      >
        <Text.H2 css={{ ml: "$3" }}>Voice Summary</Text.H2>
      </HStack>
      {dashboardConfig?.Widgets.NPSTrend?.Display && (
        <Layout.Panel width={2} height={2} data-test={"dashboard-widget"}>
          <NPSTrend />
        </Layout.Panel>
      )}
      {dashboardConfig?.Widgets.CollectionStatistics?.Display && (
        <Layout.Panel width={1} height={2} data-test={"dashboard-widget"}>
          <CollectionStatistics />
        </Layout.Panel>
      )}
      {dashboardConfig?.Widgets.TouchpointsOverview?.Display && (
        <Layout.Panel width={3} height={3} data-test={"dashboard-widget"}>
          <TouchpointsOverview />
        </Layout.Panel>
      )}
      {dashboardConfig?.Widgets.ThemesOverview?.Display && (
        <Layout.Panel width={2} height={2} data-test={"dashboard-widget"}>
          <ThemesOverview />
        </Layout.Panel>
      )}
      {dashboardConfig?.Widgets.Suggestions?.Display && (
        <Layout.Panel width={1} height={2} data-test={"dashboard-widget"}>
          <Suggestions />
        </Layout.Panel>
      )}
    </Layout.Container>
  );
};

export default Dashboard;
