import React, { useEffect } from "react";
import {
  TimeRangeContext,
  VenueContext,
  AccountContext,
} from "@pp/ui/app/GlobalContexts";
import api from "@pp/lib/net/api-service";

import { Loader, VStack, Text, Link } from "@pp/lib/ui/components";
import WidgetPanel from "./WidgetPanel";
import CommentStack from "./CommentStack";
import Analytics from "@pp/utils/analytics";

const CommentsPreview = ({ touchpoint, tooltip }) => {
  const { venue } = React.useContext(VenueContext) as any;
  const { account } = React.useContext(AccountContext) as any;
  const { timeRange } = React.useContext(TimeRangeContext) as any;
  const [comments, setComments] = React.useState(null);
  const trackLink = Analytics.useTrackLink();

  useEffect(() => {
    const makeCall = async () => {
      const result = await api.improvements({
        accountId: account.shortId,
        venueId: venue.id,
        touchpointId: touchpoint.id,
        from: new Date("2020-01-01"),
        to: timeRange.to,
      });
      const suggestions = result.Improvements.map(
        ({ Improvement, Timestamp }) => {
          return {
            text: Improvement,
            date: new Date(Timestamp),
          };
        }
      );
      const comments = result.Improvements.map(({ Improvement, Timestamp }) => {
        return {
          text: Improvement,
          date: new Date(Timestamp),
        };
      }).toReversed();
      setComments(comments);
    };
    setComments(null);
    makeCall();
  }, [timeRange, touchpoint]);

  return (
    <WidgetPanel
      title={`${touchpoint.label} Comments`}
      tooltip={tooltip}
      tooltipAnalyticEvent={"touchpoint_comments"}
    >
      <Loader
        css={{ padding: "30px", boxSizing: "border-box" }}
        data={comments}
        content={() => {
          return (
            <VStack css={{ gap: "$3", flex: 1 }}>
              <CommentStack comments={comments} />
              <Text.Small
                css={{
                  textAlign: "right",
                }}
              >
                <Link
                  to={`/comments?touchpoint=${touchpoint.id}`}
                  onClick={() => {
                    trackLink("view_all_comments");
                  }}
                >
                  View all comment data →
                </Link>
              </Text.Small>
            </VStack>
          );
        }}
      />
    </WidgetPanel>
  );
};

export default CommentsPreview;
