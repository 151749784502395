import React from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import Icon from "./Icon";
import { styled, keyframes } from "@pp/lib/ui/theme";
import { violet, mauve, blackA } from "@radix-ui/colors";
import {
  AiOutlineMenu,
  // HamburgerMenuIcon,
  // DotFilledIcon,
  // CheckIcon,
  // ChevronRightIcon,
} from "react-icons/ai";

const DropDownMenu = ({ icon = AiOutlineMenu, items }) => {
  // const [bookmarksChecked, setBookmarksChecked] = React.useState(true);
  // const [urlsChecked, setUrlsChecked] = React.useState(false);
  // const [person, setPerson] = React.useState("pedro");

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <IconButton aria-label="Customise options">
          <Icon icon={icon} />
        </IconButton>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenuContent sideOffset={5}>
          {/* <DropdownMenuItem>New Tab</DropdownMenuItem> */}
          {items.map((item, i) => {
            return (
              <DropdownMenuItem
                key={i}
                onClick={(event) => {
                  // event.preventDefault();
                  item.action();
                }}
              >
                {item.label}
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const contentStyles = {
  minWidth: 120,
  backgroundColor: "white",
  borderRadius: 6,
  padding: 5,
  boxShadow:
    "0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)",
  animationDuration: "400ms",
  animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
  willChange: "transform, opacity",
  '&[data-state="open"]': {
    '&[data-side="top"]': { animationName: slideDownAndFade },
    '&[data-side="right"]': { animationName: slideLeftAndFade },
    '&[data-side="bottom"]': { animationName: slideUpAndFade },
    '&[data-side="left"]': { animationName: slideRightAndFade },
  },
};

const DropdownMenuContent = styled(DropdownMenu.Content, contentStyles);
// const DropdownMenuSubContent = styled(DropdownMenu.SubContent, contentStyles);
/*
const DropdownMenuArrow = styled(DropdownMenu.Arrow, { fill: "white" });
*/

const itemStyles = {
  all: "unset",
  fontSize: 13,
  lineHeight: 1,
  color: violet.violet11,
  borderRadius: 3,
  display: "flex",
  alignItems: "center",
  // textAlign: "center",
  justifyContent: "center",
  height: 25,
  padding: "0 5px",
  position: "relative",
  // paddingLeft: 25,
  userSelect: "none",

  "&[data-disabled]": {
    color: mauve.mauve8,
    pointerEvents: "none",
  },

  "&[data-highlighted]": {
    backgroundColor: violet.violet9,
    color: violet.violet1,
  },
};

const DropdownMenuItem = styled(DropdownMenu.Item, itemStyles);
/*
const DropdownMenuCheckboxItem = styled(DropdownMenu.CheckboxItem, itemStyles);
const DropdownMenuRadioItem = styled(DropdownMenu.RadioItem, itemStyles);
const DropdownMenuSubTrigger = styled(DropdownMenu.SubTrigger, {
  '&[data-state="open"]': {
    backgroundColor: violet.violet4,
    color: violet.violet11,
  },
  ...itemStyles,
});

const DropdownMenuLabel = styled(DropdownMenu.Label, {
  paddingLeft: 25,
  fontSize: 12,
  lineHeight: "25px",
  color: mauve.mauve11,
});

const DropdownMenuSeparator = styled(DropdownMenu.Separator, {
  height: 1,
  backgroundColor: violet.violet6,
  margin: 5,
});

const DropdownMenuItemIndicator = styled(DropdownMenu.ItemIndicator, {
  position: "absolute",
  left: 0,
  width: 25,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
});

const RightSlot = styled("div", {
  marginLeft: "auto",
  paddingLeft: 20,
  color: mauve.mauve11,
  "[data-highlighted] > &": { color: "white" },
  "[data-disabled] &": { color: mauve.mauve8 },
});
*/
const IconButton = styled("button", {
  all: "unset",
  fontFamily: "inherit",
  borderRadius: "100%",
  height: 35,
  width: 35,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  color: violet.violet11,
  backgroundColor: "white",
  boxShadow: `0 2px 10px ${blackA.blackA7}`,
  "&:hover": { backgroundColor: violet.violet3 },
  "&:focus": { boxShadow: `0 0 0 2px black` },
});

export default DropDownMenu;

// {/* <DropdownMenu.Portal>
//         <DropdownMenuContent sideOffset={5}>
//           <DropdownMenuItem>
//             New Tab <RightSlot>⌘+T</RightSlot>
//           </DropdownMenuItem>
//           <DropdownMenuItem>
//             New Window <RightSlot>⌘+N</RightSlot>
//           </DropdownMenuItem>
//           <DropdownMenuItem disabled>
//             New Private Window <RightSlot>⇧+⌘+N</RightSlot>
//           </DropdownMenuItem>
//           <DropdownMenu.Sub>
//             <DropdownMenuSubTrigger>
//               More Tools
//               <RightSlot>{/* <ChevronRightIcon /> */}</RightSlot>
//             </DropdownMenuSubTrigger>
//             <DropdownMenu.Portal>
//               <DropdownMenuSubContent sideOffset={2} alignOffset={-5}>
//                 <DropdownMenuItem>
//                   Save Page As… <RightSlot>⌘+S</RightSlot>
//                 </DropdownMenuItem>
//                 <DropdownMenuItem>Create Shortcut…</DropdownMenuItem>
//                 <DropdownMenuItem>Name Window…</DropdownMenuItem>
//                 <DropdownMenuSeparator />
//                 <DropdownMenuItem>Developer Tools</DropdownMenuItem>
//               </DropdownMenuSubContent>
//             </DropdownMenu.Portal>
//           </DropdownMenu.Sub>
//           <DropdownMenuSeparator />
//           <DropdownMenuCheckboxItem
//             checked={bookmarksChecked}
//             onCheckedChange={setBookmarksChecked}
//           >
//             {/* <DropdownMenuItemIndicator>
//               <CheckIcon />
//             </DropdownMenuItemIndicator> */}
//             Show Bookmarks <RightSlot>⌘+B</RightSlot>
//           </DropdownMenuCheckboxItem>
//           <DropdownMenuCheckboxItem
//             checked={urlsChecked}
//             onCheckedChange={setUrlsChecked}
//           >
//             <DropdownMenuItemIndicator>
//               {/* <CheckIcon /> */}
//             </DropdownMenuItemIndicator>
//             Show Full URLs
//           </DropdownMenuCheckboxItem>
//           <DropdownMenuSeparator />
//           <DropdownMenuLabel>People</DropdownMenuLabel>
//           <DropdownMenu.RadioGroup value={person} onValueChange={setPerson}>
//             <DropdownMenuRadioItem value="pedro">
//               <DropdownMenuItemIndicator>
//                 {/* <DotFilledIcon /> */}
//               </DropdownMenuItemIndicator>
//               Pedro Duarte
//             </DropdownMenuRadioItem>
//             <DropdownMenuRadioItem value="colm">
//               <DropdownMenuItemIndicator>
//                 {/* <DotFilledIcon /> */}
//               </DropdownMenuItemIndicator>
//               Colm Tuite
//             </DropdownMenuRadioItem>
//           </DropdownMenu.RadioGroup>

//           <DropdownMenuArrow />
//         </DropdownMenuContent>
//       </DropdownMenu.Portal> */}
