import React from "react";
import { VStack, HStack, Box } from "@pp/lib/ui/components";
import { LeftPanel, Header, DialogManager } from "@pp/lib/ui/app";

const AppLayout = ({
  user,
  navItems = [],
  headerContent = null,
  mainContent = null,
}: {
  user: any;
  navItems?: Array<{ icon: any; label: string; route: string }>;
  headerContent?: React.ReactNode;
  mainContent?: React.ReactNode;
}): React.ReactNode => {
  return (
    <DialogManager>
      <HStack
        css={{
          backgroundColor: "$pageBackground",
          width: "100%",
          height: "100%",
        }}
      >
        <LeftPanel
          user={user}
          navItems={navItems}
          css={{
            width: "$navWidth",
            position: "absolute",
          }}
        />
        <VStack css={{ width: "100%" }}>
          {headerContent !== null ? (
            <HeaderBlock headerContent={headerContent}>
              {mainContent}
            </HeaderBlock>
          ) : (
            <Box
              css={{
                marginLeft: "$navCollapsedWidth",
                width: "calc($full - $navCollapsedWidth)",
                height: "$full",
                overflow: "auto",
              }}
            >
              {mainContent}
            </Box>
          )}
        </VStack>
      </HStack>
    </DialogManager>
  );
};

const HeaderBlock = ({
  headerContent,
  children,
}: {
  headerContent: React.ReactNode;
  children?: React.ReactNode;
}) => {
  return (
    <>
      <Header>{headerContent}</Header>
      <Box
        css={{
          position: "absolute",
          top: "$fullHeaderHeight",
          marginLeft: "$navCollapsedWidth",
          width: "calc($full - $navCollapsedWidth)",
          height: "calc($full - $fullHeaderHeight)",
          overflow: "auto",
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default AppLayout;
