import React, { useEffect } from "react";
import { Loader, VStack, Link, Text } from "@pp/lib/ui/components";
import CommentStack from "../../shared/CommentStack";
import WidgetPanel from "../../shared/WidgetPanel";
import {
  VenueContext,
  TimeRangeContext,
  AccountContext,
} from "@pp/ui/app/GlobalContexts";
import api from "@pp/lib/net/api-service";
import Tooltips from "@pp/ui/widgets/shared/tooltips";
import Analytics from "@pp/utils/analytics";

const Suggestions = () => {
  const { venue } = React.useContext(VenueContext);
  const { account } = React.useContext(AccountContext);
  const { timeRange } = React.useContext(TimeRangeContext);
  const [suggestions, setSuggestions] = React.useState(null);
  const trackLink = Analytics.useTrackLink();

  useEffect(() => {
    const makeCall = async () => {
      const result = await api.suggestions({
        accountId: account.shortId,
        venueId: venue.id,
        ...timeRange,
      });
      const suggestions = result.Suggestions.map(
        ({ Suggestion, Timestamp }) => {
          return {
            text: Suggestion,
            date: new Date(Timestamp),
          };
        }
      ).toReversed();

      setSuggestions(suggestions);
    };
    setSuggestions(null);
    makeCall();
  }, [venue, timeRange]);

  return (
    <WidgetPanel
      title="Recent Suggestions"
      tooltip={Tooltips.RECENT_SUGGESTIONS}
      tooltipAnalyticEvent={"recent_suggestions"}
    >
      <Loader
        css={{ padding: "30px", boxSizing: "border-box" }}
        data={suggestions}
        content={() => {
          return (
            <VStack css={{ gap: "$3" }}>
              <CommentStack comments={suggestions} />
              <Text.Small
                css={{
                  textAlign: "right",
                }}
              >
                <Link
                  to={`/comments`}
                  onClick={() => {
                    trackLink("view_all_comments");
                  }}
                >
                  View all comment data →
                </Link>
              </Text.Small>
            </VStack>
          );
          // return <CommentStack comments={suggestions} />;
        }}
      />
    </WidgetPanel>
  );
};

export default Suggestions;
