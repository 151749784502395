import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { MONTHS } from "@pp/lib/utils";
import { ChartWrapper, VStack, HStack } from "@pp/lib/ui/components";
import GraphTooltip from "./GraphTooltip";
import GraphKey from "../shared/GraphKey";
import colours from "@pp/lib/ui/theme/colours";

const DistributionGraph = ({
  data,
  distributionValues,
  tooltipTitle,
  tooltipLabels,
}) => {
  const labels = data.map(({ date }) => {
    return `${MONTHS[date.getMonth()]} ${String(date.getFullYear())}`;
  });

  const totals = data.map(({ distribution }) => {
    return Object.values(distribution as number[]).reduce(
      (acc, val) => acc + val,
      0
    );
  });

  const series = distributionValues.map((distId, i) => {
    return {
      type: "bar",
      stack: "total",
      barWidth: "60%",
      data: data.map(({ distribution }, j) => {
        return {
          value: (distribution[distId] / totals[j]) * 100,
          itemStyle: { color: colours.tealGradients[i] },
          count: distribution[distId],
        };
      }),
    };
  });

  const option = {
    yAxis: {
      type: "value",
      max: 100,
    },
    xAxis: {
      type: "category",
      data: labels,
    },
    grid: {
      left: 30,
      top: 20,
      right: 0,
      bottom: 20,
    },
    series,
    tooltip: {
      formatter: (params) => {
        const percentage = params.value.toFixed(2);
        const count = params.data.count;
        const lab = tooltipLabels[params.seriesIndex];
        return renderToStaticMarkup(
          <GraphTooltip
            title={tooltipTitle}
            items={[
              {
                label: "Score",
                value: lab,
              },
              {
                label: "Percentage",
                value: `${percentage}%`,
              },
              {
                label: "Count",
                value: count,
              },
            ]}
          />
        );
      },
      borderColor: "#FFFFFF",
    },
  };

  return (
    <VStack css={{ flex: "1", height: "100%" }}>
      <ChartWrapper option={option} style={{ flex: "1" }} />
      <HStack css={{ ml: "$4", mt: "$4", gap: "$3" }}>
        {distributionValues.map((distId, i) => {
          i = distributionValues.length - i - 1;
          return (
            <GraphKey.Rectangle
              color={colours.tealGradients[i]}
              label={tooltipLabels[i]}
              key={i}
            />
          );
        })}
      </HStack>
    </VStack>
  );
};

export default DistributionGraph;
