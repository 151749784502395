import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Layout,
  Loader,
  VStack,
  HStack,
  Text,
  SimpleSelect,
  NoData,
} from "@pp/lib/ui/components";
import { processTouchpoints, processTouchpointDetail } from "../../utils/data";
import {
  TouchpointScore,
  TouchpointTrend,
  TouchpointDetail,
  TouchpointDetailBreakdown,
  CommentsPreview,
  CommentsAnalysis,
} from "../widgets";
import api from "@pp/lib/net/api-service";
import { previousYearRange } from "@pp/lib/utils";
import Tooltips from "@pp/ui/widgets/shared/tooltips";

import {
  AccountContext,
  VenueContext,
  BenchmarkContext,
  TimeRangeContext,
} from "@pp/ui/app/GlobalContexts";

import Analytics from "@pp/utils/analytics";

const Touchpoints = () => {
  Analytics.useScreenView("customer-profiles");

  const { account } = React.useContext(AccountContext) as any;
  const { venue } = React.useContext(VenueContext) as any;
  const { benchmark } = React.useContext(BenchmarkContext) as any;
  const { timeRange } = React.useContext(TimeRangeContext) as any;

  const [touchpoints, setTouchpoints] = useState(null);
  const [activeTouchpoint, setActiveTouchpoint] = useState(null);
  const [touchpointDetail, setTouchpointDetail] = useState(null);
  const [searchParams] = useSearchParams();
  const preselectedTouchpoint = searchParams.get("touchpoint");
  const [activeTouchpointId, setActiveTouchpointId] = useState(
    preselectedTouchpoint
  );
  const trackTouchpointChange = Analytics.useTrackEvent("touchpoint-change");

  const touchpointsAPICall = async () => {
    const prevRange = previousYearRange(timeRange);
    const promises = [
      api.touchpoints({
        accountId: account.shortId,
        venueIds: [venue.id],
        benchmark: benchmark?.id,
        ...timeRange,
      }),
      api.touchpoints({
        accountId: account.shortId,
        venueIds: [venue.id],
        ...prevRange,
      }),
    ];

    const result = await Promise.all(promises);
    const tps = processTouchpoints(result[0], result[1], timeRange, prevRange);

    setTouchpoints(tps);
    let tpId = activeTouchpointId;
    if (!tpId) {
      tpId = tps[0].id;
      setActiveTouchpointId(tpId);
    }
    const tp = tps.find((t) => t.id === tpId);
    setActiveTouchpoint(tp);
  };

  const tpDetailsAPICall = async () => {
    if (!activeTouchpoint) {
      return;
    }
    const promises = [
      api.touchpointDetail({
        accountId: account.shortId,
        venueId: venue.id,
        touchpointId: activeTouchpoint.id,
        ...timeRange,
      }),
      api.touchpointDetail({
        accountId: account.shortId,
        venueId: venue.id,
        benchmark: benchmark?.id,
        touchpointId: activeTouchpoint.id,
        ...previousYearRange(timeRange),
      }),
    ];

    setTouchpointDetail(null);
    const result = await Promise.all(promises);
    const det = processTouchpointDetail(result[0], result[1]);
    setTouchpointDetail(det);
  };

  useEffect(() => {
    setTouchpoints(null);
    touchpointsAPICall();
  }, [venue, timeRange, benchmark]);

  useEffect(() => {
    setTouchpointDetail(null);
    tpDetailsAPICall();
  }, [activeTouchpoint]);

  if (touchpoints && touchpoints.length === 0) {
    return (
      <NoData message="There are no Touchpoints configured for this venue" />
    );
  }

  return (
    <Loader
      css={{ padding: "30px", boxSizing: "border-box" }}
      data={touchpoints && activeTouchpoint}
      content={() => {
        return (
          <Layout.Container>
            <HStack
              css={{
                width: "100%",
                pt: "$4",
                justifyContent: "space-between",
              }}
            >
              <Text.H2 css={{ ml: "$3" }}>Touchpoints</Text.H2>
              <SimpleSelect
                css={{ zIndex: 50 }}
                selected={activeTouchpoint}
                options={touchpoints}
                onChange={async (value) => {
                  setTouchpointDetail(null);
                  setActiveTouchpointId(value.id);
                  setActiveTouchpoint(value);
                  trackTouchpointChange({ touchpoint: value.idName });
                }}
              />
            </HStack>
            <TouchpointsDashboard
              touchpoint={activeTouchpoint}
              detail={touchpointDetail}
            />
          </Layout.Container>
        );
      }}
    />
  );
};

const TouchpointsDashboard = ({ touchpoint, detail }) => {
  const { venue } = React.useContext<{ venue? }>(VenueContext) as any;
  const touchpointsConfig = venue.uiConfig?.Pages.Touchpoints;

  return (
    <>
      {touchpointsConfig?.Widgets.TouchpointScore?.Display && (
        <Layout.Panel width={1} height={2}>
          <TouchpointScore touchpoint={touchpoint} detail={detail} />
        </Layout.Panel>
      )}
      {touchpointsConfig?.Widgets.TouchpointTrend?.Display && (
        <Layout.Panel width={2} height={2}>
          <TouchpointTrend touchpoint={touchpoint} detail={detail} />
        </Layout.Panel>
      )}
      {touchpointsConfig?.Widgets.TouchpointDetail?.Display && (
        <Layout.Panel width={1} height={2}>
          <TouchpointDetail touchpoint={touchpoint} detail={detail} />
        </Layout.Panel>
      )}
      {touchpointsConfig?.Widgets.TouchpointDetailBreakdown?.Display && (
        <Layout.Panel width={2} height={2}>
          <TouchpointDetailBreakdown touchpoint={touchpoint} detail={detail} />
        </Layout.Panel>
      )}
      {touchpointsConfig?.Widgets.CommentsAnalysis?.Display && (
        <Layout.Panel width={2} height={2}>
          <CommentsAnalysis
            touchpoint={touchpoint}
            tooltip={Tooltips.TOUCHPOINT_SUGGESTIONS}
          />
        </Layout.Panel>
      )}
      {touchpointsConfig?.Widgets.CommentsPreview?.Display && (
        <Layout.Panel width={1} height={2}>
          <CommentsPreview
            touchpoint={touchpoint}
            tooltip={Tooltips.TOUCHPOINT_COMMENTS}
          />
        </Layout.Panel>
      )}
    </>
  );
};

export default Touchpoints;
