const processStatsAggregatedData = (aggregatedData) => {
  const current = aggregatedData.ResponseCounts?.DataPoints?.map((data) => ({
    date: new Date(data.Date),
    value: data?.Count,
  }));
  return {
    data: {
      current,
      previous: [],
    },
  };
};

export { processStatsAggregatedData };
