import api from "@pp/lib/net/api-service";
import {
  processNpsScore,
  processThemeScore,
  processTouchpoints,
} from "@pp/utils/data";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  AccountContext,
  BenchmarkContext,
  VenueGroupContext,
} from "../app/GlobalContexts";
import { processStatsAggregatedData } from "./utils";
import { getDefaultTimeRange } from "@pp/lib/utils";

const INITIAL_VIEW_STATE = {
  nps: "total_portfolio",
  touchpoint: "total_portfolio",
  themes: "total_portfolio",
  statistics: "total_portfolio",
};

const usePortfolio = () => {
  // const { timeRange } = React.useContext(TimeRangeContext) as any;
  const { account } = React.useContext<{ account? }>(AccountContext);
  const { venueGroup } = React.useContext<{ venueGroup? }>(VenueGroupContext);
  const { benchmark } = React.useContext<{ benchmark? }>(BenchmarkContext);
  const [fullData, setFullData] = useState<any>(null);
  const [viewTypes, setViewTypes] = React.useState(INITIAL_VIEW_STATE);
  const [activeTouchpoint, setActiveTouchpoint] = React.useState(null);
  const [activeNpsMetric, setActiveNpsMetric] = React.useState("OverallScore");
  const [activeThemesMetric, setActiveThemesMetric] = React.useState("Design");

  const NPSPerformanceFetchData = useCallback(async () => {
    const venueIds = venueGroup?.id
      ? venueGroup.venues
      : account.venues.map((venue) => venue.id);
    const timeRange = getDefaultTimeRange();

    const promises = [
      api.nps({
        accountId: account.id,
        venueIds,
        isPortfolioView: true,
        benchmark: benchmark?.id,
        ...timeRange,
      }),
      api.touchpoints({
        accountId: account.id,
        venueIds,
        isPortfolioView: true,
        benchmark: benchmark?.id,
        ...timeRange,
      }),
      api.themes({
        accountId: account.id,
        venueIds,
        isPortfolioView: true,
        benchmark: benchmark?.id,
        ...timeRange,
      }),
      api.statistics({
        accountId: account.id,
        venueIds,
        isPortfolioView: true,
        benchmark: benchmark?.id,
        ...timeRange,
      }),
    ];
    const [npsPortfolioData, touchpointData, themesData, statistics] =
      await Promise.all(promises);
    const {
      aggregatedNpsData,
      npsVenueBreakdown,
      benchmarkData: npsBenchmarkData,
    } = npsPortfolioData;
    const {
      aggregatedTouchpointData,
      touchpointVenueBreakdown,
      benchmarkData: touchpointBenchmarkData,
    } = touchpointData;
    const {
      aggregatedThemesData,
      themesVenueBreakdown,
      benchmarkData: themesBenchmarkData,
    } = themesData;
    const { aggregatedStatisticsData, statisticsVenueBreakdown } = statistics;
    function createMetric(label, metricName, scoreKey, benchmarkKey) {
      return {
        label,
        metricName,
        ...processNpsScore(
          aggregatedNpsData?.[scoreKey],
          [],
          npsBenchmarkData,
          benchmarkKey,
          timeRange,
          null
        ),
      };
    }

    const data = [
      createMetric("NPS+ Score", "OverallScore", "OverallScore", "overall"),
      createMetric(
        "Satisfaction",
        "SatisfactionScore",
        "SatisfactionScore",
        "satisfaction"
      ),
      createMetric("Fulfilment", "ExceedScore", "ExceedScore", "exceed"),
      createMetric("Loyalty", "LoyaltyScore", "LoyaltyScore", "loyalty"),
      createMetric("Advocacy", "AdvocacyScore", "AdvocacyScore", "advocacy"),
      createMetric(
        "Preference",
        "CompetitionScore",
        "CompetitionScore",
        "competition"
      ),
    ];

    const tms = aggregatedThemesData.Themes.map(({ ThemeName, DataPoints }) => {
      return {
        id: ThemeName,
        label: ThemeName,
        ...processThemeScore(
          DataPoints,
          [],
          ThemeName,
          themesBenchmarkData,
          timeRange,
          null
        ),
      };
    });

    setFullData((prev) => ({
      ...prev,
      totalNpsPortfolio: data,
      npsVenueBreakdown: npsVenueBreakdown,
      aggregatedTouchpoints: processTouchpoints(
        {
          Touchpoints: aggregatedTouchpointData,
          BenchmarkData: touchpointBenchmarkData,
        },
        undefined,
        timeRange,
        null
      ),
      touchpointVenueBreakdown,
      themesData: tms,
      aggregatedResponseStats: processStatsAggregatedData(
        aggregatedStatisticsData
      ),
      statVenueBreakdown: statisticsVenueBreakdown,
      themesVenueBreakdown: themesVenueBreakdown,
      npsBenchmarkData: npsBenchmarkData,
      touchpointBenchmarkData: touchpointBenchmarkData,
      themesBenchmarkData: themesBenchmarkData,
    }));
  }, [account, venueGroup, benchmark]);

  useEffect(() => {
    setFullData(null);
    NPSPerformanceFetchData();
  }, [account, venueGroup, benchmark]);

  const touchpointVenueBreakdown = useMemo(() => {
    return Object.values(fullData?.touchpointVenueBreakdown ?? {}).map(
      (venue: any) =>
        venue?.Touchpoints?.[
          activeTouchpoint?.label ?? fullData?.aggregatedTouchpoints?.[0]?.label
        ]
    );
  }, [activeTouchpoint, fullData]);

  const npsLineGraphData = useMemo(() => {
    return (
      fullData?.totalNpsPortfolio.find(
        (dt) => dt.metricName == activeNpsMetric
      ) ?? []
    );
  }, [activeNpsMetric, fullData]);
  const npsBarGraphData = useMemo(
    () =>
      Object.values(fullData?.npsVenueBreakdown ?? {}).map((data) =>
        activeNpsMetric ? data[activeNpsMetric] : data[0]
      ),
    [activeNpsMetric, fullData]
  );

  const themesLineGraphData = useMemo(
    () =>
      fullData?.themesData.find((dt) => dt.label == activeThemesMetric) ?? [],
    [activeThemesMetric, fullData]
  );

  const themesBarGraphData = useMemo(
    () =>
      Object.values(fullData?.themesVenueBreakdown ?? {}).map((data) =>
        activeThemesMetric ? data[activeThemesMetric] : data[0]
      ),
    [activeThemesMetric, fullData]
  );
  return {
    fullData,
    touchpointVenueBreakdown,
    viewTypes,
    setViewTypes,
    activeTouchpoint,
    setActiveTouchpoint,
    account,
    activeNpsMetric,
    setActiveNpsMetric,
    npsLineGraphData,
    npsBarGraphData,
    activeThemesMetric,
    setActiveThemesMetric,
    themesLineGraphData,
    themesBarGraphData,
  };
};

export default usePortfolio;
