import { Icons } from "@pp/lib/ui/app";
import { Layout, HStack, Panel, Text } from "@pp/lib/ui/components";
import { colours } from "@pp/lib/ui/theme";
import React from "react";
import GraphKey from "../widgets/shared/GraphKey";
import { StackedBar, ProgressBar } from "./BarComponents";

export const BREAKDOWN = "breakdown";
export const NET_AGREEMENT = "netAgreement";

export const QuestionWidget = ({ item, activeViews, setActiveViews }) => {
  return (
    <Layout.Panel
      width={2}
      height={1}
      key={item.id}
      style={{
        flex: "1 1 calc(50% - 16px)", // 50% width minus spacing
        maxWidth: "50%",
        minWidth: "300px", // Ensures responsiveness for laptops
        boxSizing: "border-box",
        height: "140px",
      }}
    >
      <Panel
        css={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "12px 12px",
        }}
      >
        <Panel.Content
          css={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            gap: "8px",
          }}
        >
          <HStack>
            <Text>{`Q.  ${item.question}`}</Text>
            <Panel.Actions>
              <Panel.ActionButton
                onClick={() =>
                  setActiveViews({
                    ...activeViews,
                    [item.id]: BREAKDOWN,
                  })
                }
                icon={Icons.BarGraph}
              />
              <Panel.ActionButton
                onClick={() =>
                  setActiveViews({
                    ...activeViews,
                    [item.id]: NET_AGREEMENT,
                  })
                }
                icon={Icons.Smile}
              />
            </Panel.Actions>
          </HStack>
          <div style={{ height: "35px" }}>
            {activeViews[item.id] === BREAKDOWN ? (
              <StackedBar data={item.responses} />
            ) : (
              <ProgressBar percentage={item.netAgreement} />
            )}
          </div>
          <GraphKey.Set
            css={{ gap: "$1" }}
            keys={
              activeViews[item.id] === BREAKDOWN
                ? [
                    {
                      label: "Strongly Disagree",
                      color: colours.agreeColours.stronglyDisagree,
                    },
                    {
                      label: "Disagree",
                      color: colours.agreeColours.disagree,
                    },
                    {
                      label: "Neutral",
                      color: colours.agreeColours.neutral,
                    },
                    {
                      label: "Agree",
                      color: colours.agreeColours.agree,
                    },
                    {
                      label: "Strongly Agree",
                      color: colours.agreeColours.stronglyAgree,
                    },
                  ]
                : [
                    {
                      label: "Net Agreement",
                      color: colours.agreeColours.stronglyDisagree,
                    },
                  ]
            }
          />
        </Panel.Content>
      </Panel>
    </Layout.Panel>
  );
};
