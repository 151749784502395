import React, { useEffect } from "react";
import {
  TimeRangeContext,
  VenueContext,
  AccountContext,
} from "@pp/ui/app/GlobalContexts";
import api from "@pp/lib/net/api-service";

import {
  Loader,
  HStack,
  VStack,
  Swatch,
  Text,
  List,
  NoData,
} from "@pp/lib/ui/components";
import WidgetPanel from "./WidgetPanel";
import Analytics from "@pp/utils/analytics";

const CommentsAnalysis = ({ touchpoint, tooltip }) => {
  const { venue } = React.useContext(VenueContext) as any;
  const { account } = React.useContext(AccountContext) as any;
  const { timeRange } = React.useContext(TimeRangeContext) as any;
  const [analysis, setAnalysis] = React.useState(null);
  const [activeTheme, setActiveTheme] = React.useState(null);
  const trackWidgetTab = Analytics.useWidgetTab("touchpoint_top_suggestions");

  useEffect(() => {
    const makeCall = async () => {
      const result = await api.commentAnalysis({
        accountId: account.shortId,
        venueId: venue.id,
        touchpointId: touchpoint.id,
        to: timeRange.to,
      });

      const totalComments = result.input.totalComments;
      result.themes.forEach((t: any) => {
        t.ratio = t.contributingComments.length / totalComments;
        t.id = Math.random();
      });

      result.themes.sort((a: any, b: any) => {
        return b.ratio - a.ratio;
      });

      setActiveTheme(result.themes[0]);
      setAnalysis(result);
    };
    setAnalysis(null);
    makeCall();
  }, [timeRange, touchpoint]);

  return (
    <WidgetPanel
      title="Top Suggestions"
      tooltip={tooltip}
      tooltipAnalyticEvent={"touchpoint_top_suggestions"}
    >
      <Loader
        css={{ padding: "30px", boxSizing: "border-box" }}
        data={analysis}
        content={() => {
          if (analysis.themes.length === 0) {
            return <NoData />;
          }
          return (
            <HStack css={{ gap: "$6", width: "100%" }}>
              <ThemesMenu
                analysis={analysis.themes}
                active={activeTheme}
                onSelect={(theme) => {
                  setActiveTheme(theme);
                  trackWidgetTab(theme.title);
                }}
              />
              <ThemeDetail theme={activeTheme} />
            </HStack>
          );
        }}
      />
    </WidgetPanel>
  );
};

const ThemesMenu = ({ analysis, active, onSelect }) => {
  return (
    <VStack css={{ width: "35%" }}>
      <HStack
        css={{
          width: "100%",
          boxSizing: "border-box",
          padding: "$2",
          justifyContent: "space-between",
        }}
      >
        <Text.Small>Suggestion</Text.Small>
        <Text.Small>Ratio</Text.Small>
      </HStack>
      <Swatch.VSet css={{ width: "100%", height: "100%", overflowY: "auto" }}>
        {analysis.map((a) => {
          const { id, title, ratio } = a;
          return (
            <React.Fragment key={id}>
              <CommentSwatch
                title={title}
                score={`${Math.round(ratio * 100).toString()}`}
                active={active === a}
                onClick={() => {
                  onSelect(a);
                }}
              />
            </React.Fragment>
          );
        })}
      </Swatch.VSet>
    </VStack>
  );
};

const ThemeDetail = ({ theme }) => {
  return (
    theme && (
      <VStack
        css={{ width: "65%", height: "100%", overflowY: "auto", gap: "$3" }}
      >
        <Text.H2>{theme.title}</Text.H2>
        <Text>{theme.summary}</Text>
        <List.Unorderd
          itemCSS={{ marginBottom: "$4" }}
          items={theme.suggestions}
        />
        <VStack css={{ flex: 1, justifyContent: "flex-end" }}>
          <Text.Tiny css={{ color: "$grey2", textAlign: "center" }}>
            Top Customer Themes and Suggestions are generated by AI technology.
            Such content is provided for informational purposes only and should
            not be relied upon for any specific purpose without verification of
            its accuracy or completeness.
          </Text.Tiny>
        </VStack>
      </VStack>
    )
  );
};

const CommentSwatch = (props) => {
  let { title, score } = props;
  if (!isNaN(score)) {
    score = Math.round(score);
  }

  if (props.formatter) {
    score = props.formatter(score);
  }

  return (
    <Swatch.Base {...props}>
      <HStack
        css={{
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Text.Small>{title}</Text.Small>
        <Text.H1>{score}%</Text.H1>
      </HStack>
    </Swatch.Base>
  );
};

export default CommentsAnalysis;
