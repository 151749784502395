import React from "react";
import { HStack, VStack, Text } from "@pp/lib/ui/components";
import colours from "@pp/lib/ui/theme/colours";

import { BenchmarkContext } from "@pp/ui/app/GlobalContexts";
import GraphKey from "../shared/GraphKey";
import LineGraphByMonth from "../shared/LineGraphByMonth";

const TouchpointLineGraph = ({ touchpoint, footerLink = null }) => {
  const { benchmark } = React.useContext<{ benchmark? }>(BenchmarkContext);
  return (
    <VStack css={{ flex: "1", height: "100%" }}>
      <LineGraphByMonth
        data={touchpoint?.data}
        benchmark={touchpoint?.benchmark?.data}
        tooltipFormatter={LineGraphByMonth.tooltipHelper(
          `${touchpoint.label} Score`,
          benchmark?.label
        )}
        // percentiles={LineGraphByMonth.percentileHelper(0, 25, 75, 100)}
      />
      <HStack css={{ ml: "$4", mt: "$4", gap: "$3" }}>
        <GraphKey.Circle
          color={colours.ppForest}
          label={`${touchpoint.label} Score`}
        />
        {touchpoint?.data?.previous?.length > 0 && (
          <GraphKey.Circle
            color={colours.greys.grey1}
            label="Previous Period"
          />
        )}
        {benchmark && touchpoint?.benchmark?.data?.length > 0 && (
          <GraphKey.Circle
            color={colours.greys.grey3}
            label={benchmark.label}
          />
        )}
        {footerLink && (
          <Text.Small
            css={{
              color: "$ppForest",
              textAlign: "right",
              textDecoration: "underline",
              alignSelf: "flex-end",
              flex: 1,
            }}
          >
            {footerLink}
          </Text.Small>
        )}
      </HStack>
    </VStack>
  );
};

export default TouchpointLineGraph;
